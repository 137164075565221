import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "src/shared/config/properties";
import { AUTH_TOKEN } from "src/shared/constants/auth";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  if (token && config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    if (
      !["/login", "/esqueci", "/nova-senha"].includes(window.location.pathname)
    ) {
      window.location.href = "/login";
      localStorage.clear();
    }
  }

  return error.response;
});

export default axiosInstance;
