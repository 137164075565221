import styled from "styled-components";

export const Content = styled.div`
  background-color: #5f0329;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  color: #fff;

  @media (min-width: 1024px) {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr; */
  }

  h2 {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 30px;

    ${(props) => props.theme.breakpoints.down("sm")} {
      padding-bottom: 20px;
      font-size: 30px;
    }
  }
`;

export const Contents = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
