import React from "react";

import { Button, Typography } from "@mui/material";
// import { defineMessages, useIntl } from "react-intl";

import * as Styled from "./styles";
import { IOrder } from "src/shared/types/Order";
import { DEFAULT_AVATAR } from "src/shared/config/properties";
import { ReactComponent as MasterCard } from "src/assets/svg/mastercardIcon.svg";
import { ReactComponent as Duplicate } from "src/assets/svg/Duplicate.svg";
import { ReactComponent as Truck } from "src/assets/svg/navbar/Truck.svg";

import { ReactComponent as ArrowUpIcon } from "src/assets/svg/ArrowUp.svg";
import { IOrderProduct } from "src/shared/types/Products";
import { formatMoney } from "src/shared/providers/money";
import { InvoiceModal } from "src/components/Invoice/modalInvoice";
import { deliveryOrder, tagOrder } from "src/shared/services/Orders";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { useAuth } from "src/shared/providers/auth";

interface IProps {
  data: IOrder;
}

// const messages = defineMessages({
//   title: { id: "order.resume.title" },
//   total: { id: "order.resume.total" },
//   subTotal: { id: "order.resume.subtotal" },
//   shipping: { id: "cart.shipping" },
// });

const OrderResume: React.FC<IProps> = ({ data }) => {
  const [inRoute, setInRoute] = React.useState<boolean>(true);
  const { openSnackbar } = useSnackbarConsumer();
  // const { formatMessage } = useIntl();
  const { user } = useAuth();
  const handleDispatch = async () => {
    const response = await deliveryOrder(data.id);
    if ([200, 201].includes(response.status)) {
      openSnackbar({
        message: "Pedido encaminhado para transportadora.",
        severity: "success",
      });
      console.log(response.data)
      //setTimeout(() => {
      //  window.location.href = "/pedidos";
      //}, 1000);
    } else {
      openSnackbar({
        message: "Falha ao enviar, tente novamente.",
        severity: "error",
      });
    }
  };

  const handleDispatchTag = async () => {
    const { status } = await tagOrder(data.id);
    if ([200, 201].includes(status)) {
      openSnackbar({
        message: "Etiqueta Gerado com sucesso, aguarde...",
        severity: "success",
      });

      setTimeout(() => {
        window.open(`https://api.besthunterwine.com.br/tags/${data.id}.pdf`,'_blank')
      }, 3000);
    } else {
      openSnackbar({
        message: "Falha ao enviar, tente novamente.",
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    if (
      data.statuses &&
      data.statuses[0] &&
      data.statuses[0].status === "PAYMENT_ACCEPTED"
    ) {
      setInRoute(false);
    }
  }, [data]);

  return (
    <>
      <Styled.Products>
        <Styled.ButtonsTop>
          <InvoiceModal
            invoiceId={data.id}
            invoiceNumber={data.invoiceNumber || ""}
            invoiceLink={data.invoiceURL || ""}
            onClose={() => window.location.reload()}
          />
          {data && data.invoiceNumber && data.invoiceNumber.length > 0 && (
            <Button
              style={{
                backgroundColor: "#fff",
                border: "1px solid #7F0E3D",
                margin: "24px 0",
                padding: "12px 12px",
                width: "100%",
              }}
              onClick={() => handleDispatch()}
              disabled={inRoute}
            >
              <Truck />
              <Typography
                style={{
                  color: "#7F0E3D",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "12px",
                  width: "100%",
                }}
              >
                Despachar
              </Typography>
            </Button>
          )}
          {data && data.trackingReference !== '' && data.trackingReference !== undefined &&  data.invoiceNumber && data.invoiceNumber.length > 0 && (
            <Button
              style={{
                backgroundColor: "#fff",
                border: "1px solid #7F0E3D",
                margin: "24px 4px",
                padding: "12px 12px",
                width: "100%",
              }}
              onClick={() => handleDispatchTag()}
            >
              <Truck />
              <Typography
                style={{
                  color: "#7F0E3D",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "12px",
                  width: "100%",
                }}
              >
                Etiqueta
              </Typography>
            </Button>
          )}          
        </Styled.ButtonsTop>
        <Styled.HeaderIcon>
          <Typography variant="h2">Detalhes do pedido</Typography>
          <ArrowUpIcon />
        </Styled.HeaderIcon>
        {data &&
          data.products &&
          data.products.length > 0 &&
          data.products.map((orderProduct: IOrderProduct, indexP: number) => {
            if (orderProduct !== undefined && orderProduct.partnerHasProduct !== null) {
            const { product } = orderProduct.partnerHasProduct;
            return (
              <Styled.Product key={`pro${indexP}`}>
                <Styled.IN>
                  <Styled.Image>
                    <img
                      src={product.image || DEFAULT_AVATAR}
                      width="14"
                      height="50"
                      alt={product.name}
                    />
                  </Styled.Image>
                  <Styled.CircleNumber>
                    <Typography>{orderProduct.amount}</Typography>
                  </Styled.CircleNumber>
                  <Styled.Name>
                    <Typography>{product.name}</Typography>
                  </Styled.Name>
                </Styled.IN>
                <Styled.Price>
                  <Typography>{formatMoney(orderProduct.total)}</Typography>
                </Styled.Price>
              </Styled.Product>
            );}
          })}

        <Styled.SubTotal>
          <Styled.SubTotalTitle>
            <Typography>Subtotal:</Typography>
          </Styled.SubTotalTitle>
          <Styled.SubTotalPrice>
            <Typography>{data.total && formatMoney(data.total)}</Typography>
          </Styled.SubTotalPrice>
        </Styled.SubTotal>
        <Styled.Freight>
          <Typography variant="h5">Frete:</Typography>
          <Typography>
            {data.freightTotal && formatMoney(data.freightTotal)}
          </Typography>
        </Styled.Freight>
        <Styled.Total>
          <Styled.TotalTitle>
            <Typography>Total:</Typography>
          </Styled.TotalTitle>
          <Styled.TotalPrice>
            <Typography>
              {formatMoney(data.total + data.freightTotal)}
            </Typography>
          </Styled.TotalPrice>
        </Styled.Total>
      </Styled.Products>
      <Styled.Payment>
        <Styled.HeaderDetailsPayment>
          <Styled.HeaderIcon>
            <Typography variant="h2">Detalhes do pagamento</Typography>
            <ArrowUpIcon />
          </Styled.HeaderIcon>
        </Styled.HeaderDetailsPayment>
        <Styled.Content>
          <Typography variant="h3">Método de pagamento</Typography>
          <Styled.CardAndIcon>
            <Typography>{data.installments}x sem juros no cartão</Typography>
            <MasterCard />
          </Styled.CardAndIcon>
        </Styled.Content>
      </Styled.Payment>
      <Styled.OrderDelivered>
        <Styled.HeaderOrderDelivered>
          <Styled.HeaderIcon>
            <Typography variant="h2">Entrega</Typography>
            <ArrowUpIcon />
          </Styled.HeaderIcon>
        </Styled.HeaderOrderDelivered>
        <Styled.Content>
          <Typography variant="h3">Código de rastreamento</Typography>
          <Styled.CodeAndCopy>
            <Typography>
              {data.trackingReference || "Não disponível"}
            </Typography>
            <Duplicate />
          </Styled.CodeAndCopy>
        </Styled.Content>
      </Styled.OrderDelivered>
      {data && data.partner && user && user.role === "ADMIN" && (
        <Styled.PersonalDataUser>
          <Styled.HeaderPersonalDataUser>
            <Styled.HeaderIcon>
              <Typography variant="h2">Dados do Vendedor</Typography>
              <ArrowUpIcon />
            </Styled.HeaderIcon>
          </Styled.HeaderPersonalDataUser>

          <Styled.ContentUser>
            <Typography variant="h3">Nome</Typography>
            <Typography>{`${data.partner.name}`}</Typography>
          </Styled.ContentUser>
        </Styled.PersonalDataUser>
      )}      
      {data && data.user && (
        <Styled.PersonalDataUser>
          <Styled.HeaderPersonalDataUser>
            <Styled.HeaderIcon>
              <Typography variant="h2">Dados do comprador</Typography>
              <ArrowUpIcon />
            </Styled.HeaderIcon>
          </Styled.HeaderPersonalDataUser>

          <Styled.ContentUser>
            <Typography variant="h3">Nome</Typography>
            <Typography>{`${data.user.name} ${data.user.surname}`}</Typography>
          </Styled.ContentUser>

          <Styled.ContentUser>
            <Typography variant="h3">CPF</Typography>
            <Typography>{data.user.document || "Não definido"}</Typography>
          </Styled.ContentUser>
          <Styled.ContentUser>
            <Typography variant="h3">Endereço</Typography>
            {data.address && (
              <Typography>
                {data.address.street},{data.address.number}, {data.address.complement} - {" "}
                {data.address.neighborhood}
                <br />
                {data.address.city}/{data.address.state} -{" "}
                {data.address.zipCode}
              </Typography>
            )}
          </Styled.ContentUser>
        </Styled.PersonalDataUser>
      )}
    </>
  );
};

export { OrderResume };
