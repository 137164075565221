import React from "react";
import { Typography } from "@mui/material";

import * as Styled from "./styles";

const HelpFooter: React.FC = () => {
  return (
    <Styled.Institutional>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        Ajuda
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/contato">Fale conosco</a>
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/politica-privacidade">
          Política de Privacidade
        </a>
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/termos-de-uso">
          Termos de uso
        </a> 
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/faq">FAQ</a>
      </Typography>
    </Styled.Institutional>
  );
};

export { HelpFooter };
