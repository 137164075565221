import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Pages = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
`;

export const Page = styled.a`
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: ${colors.wine};
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  margin: 0 5px;

  &.active,
  :hover {
    background-color: ${colors.wine};
    color: ${colors.white};
  }
`;
