import React from "react";
// import { Typography } from "@mui/material";
import { OrderResume } from "src/components/OrderResume";
// import { defineMessages, useIntl } from "react-intl";
import { OrderHeader } from "src/components/OrderHeader";

// import * as Styled from "./styles";
import { IOrder } from "src/shared/types/Order";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { order } from "src/shared/services/Users";

interface IProps {
  query?: any;
}

// const messages = defineMessages({
//   needHelp: { id: "order.need.help" },
//   talkToUs: { id: "order.talk.to.us" },
// });

const OrderPage: React.FC<IProps> = ({ query }) => {
  const { id } = useParams();
  if (!id) window.location.href = "/pedidos";
  // const { formatMessage } = useIntl();

  const [data, setData] = React.useState<IOrder>({} as IOrder);

  const handleOrder = React.useCallback(async () => {
    const { status, data } = await order(Number(id));
    if ([200, 201].includes(status)) {
      setData(data);
    }
  }, [id]);

  React.useEffect(() => {
    handleOrder();
  }, [handleOrder]);

  return (
    <>
      {data && (
        <Container maxWidth="sm">
          <OrderHeader
            id={data.id}
            price={data.total + data.freightTotal || 0}
            date={data.createdAt || ""}
            trackingReference={data.trackingReference || ""}
          />
          <OrderResume data={data} />
        </Container>
      )}
    </>
  );
};

export { OrderPage };
