import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as ArrowGoBack } from "src/assets/svg/ArrowGoBack.svg";
import { ReactComponent as Plus } from "src/assets/svg/Plus.svg";

import * as Styled from "./styles";
import { useAuth } from "src/shared/providers/auth";

interface IProps {
  label: string;
  link?: string;
  add?: boolean;
  uri?: string;
  onClick?: () => void;
}

const HeaderInternal: React.FC<IProps> = ({
  label,
  link,
  add,
  uri,
  onClick,
}) => {
  const { user } = useAuth();
  return (
    <Styled.Content>
      <Styled.Header>
        <Styled.GoBack style={{cursor:'pointer'}} onClick={(e) => window.history.back()} href={uri}>
          <ArrowGoBack />
          <Typography variant="h3">{label}</Typography>
        </Styled.GoBack>
        {user && user.role === "ADMIN" && (
          <Styled.Action>
          {add && (
            <Typography>
              {!onClick ? (
                <a href={`/${uri || ""}/form`}>
                  Adicionar novo&nbsp;
                  <Plus />
                </a>
              ) : (
                <button type="button" onClick={() => {/** onClick()*/}}>
                  <Typography>Adicionar novo&nbsp;</Typography>
                  <Plus />
                </button>
              )}
            </Typography>
          )}
        </Styled.Action>  
        )}
        
      </Styled.Header>
    </Styled.Content>
  );
};

export { HeaderInternal };
