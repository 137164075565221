import {
  IProductsResponse,
  IProductResponse,
  IProduct,
  IProductsListResponse,
} from "src/shared/types/Products";
import axiosInstance from "src/shared/services/api";

const uri = "product";

export const all = async (): Promise<IProductsResponse> => {
  return axiosInstance.get(`/${uri}`);
};

export const list = async (
  page: number = 1
): Promise<IProductsListResponse> => {
  return axiosInstance.get(`/${uri}/list/${page}`);
};

export const getById = async (id: number): Promise<IProductResponse> => {
  return axiosInstance.get(`/${uri}/${id}`);
};

export const create = async (values: IProduct): Promise<IProductResponse> => {
  return axiosInstance.post(`/${uri}`, values);
};

export const update = async (
  values: IProduct,
  id: number
): Promise<IProductResponse> => {
  return axiosInstance.put(`/${uri}/${id}`, values);
};

export const remove = async (id: number): Promise<IProductResponse> => {
  return axiosInstance.delete(`${uri}/${id}`);
};
