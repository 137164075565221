import React from "react";
import { ReactComponent as Plus } from "src/assets/svg/Plus.svg";
import { ReactComponent as Close } from "src/assets/svg/Close.svg";

import * as Styled from "./styles";

interface IProps {
  unique?: boolean;
  onChange: (values: any) => void;
}

const GalleryForm: React.FC<IProps> = ({ unique, onChange }) => {
  const img1 = React.useRef(null);
  const img2 = React.useRef(null);
  const img3 = React.useRef(null);
  const img4 = React.useRef(null);

  const [images, setImages] = React.useState<any>({
    img1: "",
    img2: "",
    img3: "",
    img4: "",
  });

  const handleImage = (ref: any) => {
    ref.current.click();
  };

  const handleFile = (event: any, id: number) => {
    const url = URL.createObjectURL(event.target.files[0]);
    const imgs: any = { ...images };
    imgs[`img${id}`] = url;
    setImages({ ...imgs });
    onChange({ ...imgs });
  };

  const handleRemove = (id: number) => {
    const imgs: any = { ...images };
    delete imgs[`img${id}`];
    setImages({ ...imgs });
  };

  return (
    <Styled.Main>
      <Styled.Image onClick={() => handleImage(img1)}>
        {images.img1 ? <img src={images.img1} alt="" /> : <Plus />}
        {images.img1 && (
          <Close onClick={() => handleRemove(1)} className="remove" />
        )}
        <input
          type="file"
          id="file"
          ref={img1}
          style={{ display: "none" }}
          onChange={(e) => handleFile(e, 1)}
        />
      </Styled.Image>
      {!unique && (
        <>
          <Styled.Image onClick={() => handleImage(img2)}>
            {images.img2 ? <img src={images.img2} alt="" /> : <Plus />}
            {images.img2 && (
              <Close onClick={() => handleRemove(2)} className="remove" />
            )}
            <input
              type="file"
              id="file"
              ref={img2}
              style={{ display: "none" }}
            />
          </Styled.Image>
          <Styled.Image onClick={() => handleImage(img3)}>
            {images.img3 ? <img src={images.img3} alt="" /> : <Plus />}
            {images.img3 && (
              <Close onClick={() => handleRemove(3)} className="remove" />
            )}
            <input
              type="file"
              id="file"
              ref={img3}
              style={{ display: "none" }}
            />
          </Styled.Image>
          <Styled.Image onClick={() => handleImage(img4)}>
            {images.img4 ? <img src={images.img4} alt="" /> : <Plus />}
            {images.img4 && (
              <Close onClick={() => handleRemove(4)} className="remove" />
            )}
            <input
              type="file"
              id="file"
              ref={img4}
              style={{ display: "none" }}
            />
          </Styled.Image>
        </>
      )}
    </Styled.Main>
  );
};

export { GalleryForm };
