import React from "react";
import { Container, CircularProgress } from "@mui/material";
import { HeaderInternal } from "src/components/HeaderInternal";
import { ListItem } from "src/components/ListItem";
import { Paginate } from "src/components/Paginate";
import withAuthenticationRequired from "src/shared/hocs/with-authentication-required";
import { list } from "src/shared/services/Partners";
import { IUser } from "src/shared/types/User";
import { IMeta } from "src/shared/types/Pagination";
import { NoResultsList } from "src/components/NoResultsList";

import * as Styled from "src/shared/theme/form-styles";

const uri = "fornecedores";

const ProvidersPage: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<IUser[]>([]);
  const [meta, setMeta] = React.useState<IMeta>({ page: 1, totalPages: 1 });

  const handleData = React.useCallback(async () => {
    try {
      setLoading(true);
      const { status, data: partners } = await list(meta.page);
      if (status === 200) {
        setData(partners.data);
        setMeta(partners.meta);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [meta.page]);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal label="Fornecedores" add={true} uri={uri} />
        {data && data.length >= 1 ? (
          <>
            {!loading ? (
              <>
                <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "10px",
                }} 
                >
                  {data.map((item, index: number) => (
                    <ListItem
                      key={`idx${index}`}
                      image={
                        item.avatar || "https://via.placeholder.com/94x82.jpg"
                      }
                      title={item.name}
                      subtitle={item.cellphone}
                      extra={item.email}
                      url={`${uri}/form/${item.id}`}
                    />
                  ))}
                </div>
                <Paginate
                  actualPage={meta.page}
                  totalPages={meta.totalPages}
                  uri={uri}
                />
              </>
            ) : (
              <Styled.Loading>
                <CircularProgress size={30} />
              </Styled.Loading>
            )}
          </>
        ) : (
          <Styled.NoResults>
            <NoResultsList uri={uri} />
          </Styled.NoResults>
        )}
      </Styled.Main>
    </Container>
  );
};

export default withAuthenticationRequired(ProvidersPage);
