import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
`;

export const Menu = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Logo = styled.h1`
  margin-left: 52px;
  span {
    font-size: 0;
  }
`;
