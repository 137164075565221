import React from "react";
import { Typography } from "@mui/material";

import * as Styled from "./styles";

interface IProps {
  actualPage: number;
  totalPages: number;
  uri: string;
}

const Paginate: React.FC<IProps> = ({ actualPage, totalPages, uri }) => {
  const [items, setItems] = React.useState<number[]>([]);

  const handleArr = React.useCallback(() => {
    const it: number[] = [];
    for (let i = 1; i <= totalPages; i++) it.push(i);
    setItems(it);
  }, [totalPages]);

  React.useEffect(() => {
    handleArr();
  }, [handleArr]);

  return (
    <Styled.Pages>
      {items.map((item: number) => (
        <Styled.Page
          key={`pg${item}`}
          href={`/${uri}?page=${item}`}
          className={item === actualPage ? "active" : ""}
        >
          <Typography>{item}</Typography>
        </Styled.Page>
      ))}
    </Styled.Pages>
  );
};

export { Paginate };
