import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Main = styled.div`
  display: flex;
  height: 100%;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    padding: 0 20px;
  }
`;

export const Logo = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.partnersGray};
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    height: auto;
    background-color: ${colors.white};
    svg {
      width: 190px;
    }
  }
`;

export const BoxLogin = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    h1 {
      font-size: 24px;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  margin-bottom: 20px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.black};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Remeber = styled.div``;
export const Forget = styled.div`
  p {
    a {
      font-size: 10px;
      font-weight: 600;
      color: ${colors.rose};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Footer = styled.div`
  min-width: 395px;
  text-align: center;
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  p {
    font-size: 14px;
    font-weight: 400;
    a {
      color: ${colors.rose};
      text-decoration: none;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    min-width: 100%;
  }
`;
