export interface IMenu {
  ADMIN?: IMenuItem[];
  PARTNER?: IMenuItem[];
}

export interface IMenuItem {
  icon: string;
  url: string;
  label: string;
  protected: boolean;
}

export const menu: IMenu = {
  ADMIN: [
    {
      icon: "Home",
      url: "/",
      label: "Início",
      protected: true,
    },
    {
      icon: "Truck",
      url: "/fornecedores",
      label: "Fornecedores",
      protected: true,
    },
    {
      icon: "Truck",
      url: "/pedidos",
      label: "Pedidos",
      protected: true,
    },
    {
      icon: "Smile",
      url: "/assinantes",
      label: "Clube de Assinaturas",
      protected: true,
    },
    {
      icon: "Truck",
      url: "/produtos",
      label: "Produtos",
      protected: true,
    },
    {
      icon: "Truck",
      url: "/uvas",
      label: "Uvas",
      protected: true,
    },
    {
      icon: "Truck",
      url: "/paises",
      label: "Países",
      protected: true,
    },
    {
      icon: "Smile",
      url: "/usuarios",
      label: "Usuários",
      protected: true,
    },
  ],
  PARTNER: [
    {
      icon: "Home",
      url: "/",
      label: "Início",
      protected: true,
    },
    {
      icon: "MyAccount",
      url: "/pedidos",
      label: "Pedidos",
      protected: true,
    },
    {
      icon: "MyOrders",
      url: "/produtos-parceiro",
      label: "Produtos",
      protected: true,
    },
  ],
};
