/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_AFTER_LOGOUT } from "src/shared/constants/auth";
import { useAuth } from "src/shared/providers/auth";

function withAuthenticationRequired<P>(
  Component: React.ComponentType<P>
): React.FC<P> {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { isLogged } = useAuth();

    return isLogged ? (
      //@ts-ignore
      <Component {...props} />
    ) : (
      <Navigate to={ROUTE_AFTER_LOGOUT} />
    );
  };
}

export default withAuthenticationRequired;
