import React from "react";
import { IOccasion } from "@shared/types/Occasions";
import { all } from "src/shared/services/Occasions";

export function useOccasions() {
  const [occasions, setOccasions] = React.useState<IOccasion[]>([]);

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await all();
      if (status === 200) {
        setOccasions(data);
      }
      throw new Error();
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return { occasions };
}
