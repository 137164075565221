import Typography from "@mui/material/Typography";
import React from "react";

interface IProps {
  label: string;
  color: any;
}

const colors = {
  warning: "#FFB754",
  success: "#16C47B",
  error: "#cf513d",
};

//@ts-ignore
const handleColor = (color: any) => colors[color];

const StatusType: React.FC<IProps> = ({ label, color }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>{label}</Typography>
      <div
        style={{
          paddingLeft: "10px",
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: handleColor(color),
            borderRadius: "100%",
          }}
        ></div>
      </div>
    </div>
  );
};
export { StatusType };
