import {
  IPartnerProductsResponse,
  IPartnerProduct,
  IPartnerProductsListResponse,
} from "src/shared/types/PartnerProducts";
import axiosInstance from "src/shared/services/api";

const uri = "partner-product";

export const list = async (
  page: number = 1
): Promise<IPartnerProductsListResponse> => {
  return axiosInstance.get(`/${uri}/list/${page}`);
};

export const getById = async (
  id: number
): Promise<IPartnerProductsResponse> => {
  return axiosInstance.get(`/${uri}/${id}`);
};

export const create = async (
  values: IPartnerProduct
): Promise<IPartnerProductsResponse> => {
  return axiosInstance.post(`/${uri}`, values);
};

export const update = async (
  values: IPartnerProduct,
  id: number
): Promise<IPartnerProductsResponse> => {
  return axiosInstance.put(`/${uri}/${id}`, values);
};

export const remove = async (id: number): Promise<IPartnerProductsResponse> => {
  return axiosInstance.delete(`${uri}/${id}`);
};
