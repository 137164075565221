/* eslint-disable array-callback-return */
import React from "react";
import { Drawer, Avatar, Typography } from "@mui/material";

import { ReactComponent as Home } from "src/assets/svg/navbar/Home.svg";
import { ReactComponent as MyAccount } from "src/assets/svg/navbar/MyAccount.svg";
import { ReactComponent as MyOrders } from "src/assets/svg/navbar/MyOrders.svg";
import { ReactComponent as Exit } from "src/assets/svg/navbar/Exit.svg";
import { ReactComponent as Help } from "src/assets/svg/navbar/Help.svg";
import { ReactComponent as Truck } from "src/assets/svg/navbar/Truck.svg";
import { ReactComponent as Smile } from "src/assets/svg/navbar/Smile.svg";

import { useAuth } from "src/shared/providers/auth";
import { IUserRole } from "src/shared/types/User";
import { menu, IMenuItem } from "./menu";

import * as Styled from "./styles";

interface IProps {
  open: boolean;
  loggedIn: boolean;
  onClose: () => void;
}

const icons: any = {
  Home: <Home />,
  MyAccount: <MyAccount />,
  MyOrders: <MyOrders />,
  Help: <Help />,
  Truck: <Truck />,
  Smile: <Smile />,
};

const Navigation: React.FC<IProps> = ({ open, loggedIn, onClose }) => {
  const [nav, setNav] = React.useState<IMenuItem[]>([]);
  const { signout, user } = useAuth();
  const role: IUserRole = user.role;

  const handleMenu = React.useCallback(() => {
    if (role) {
      if (role === "ADMIN" && menu.ADMIN) setNav(menu.ADMIN);
      if (role === "PARTNER" && menu.PARTNER) setNav(menu.PARTNER);
    }
  }, [role]);

  React.useEffect(() => {
    handleMenu();
  }, [handleMenu]);

  return (
    <Drawer anchor="left" open={open} onClose={() => onClose()}>
      {user.id && (
        <Styled.Content>
          <Styled.Header>
            <Styled.HeaderAvatar>
              <Avatar
                alt={user.name}
                src={user.avatar}
                sx={{ width: 80, height: 80 }}
              >
                {user.name[0]}
              </Avatar>
            </Styled.HeaderAvatar>
            <Styled.HeaderInfo>
              <Typography>Olá,</Typography>
              {loggedIn ? (
                <Typography>{user.name}</Typography>
              ) : (
                <Styled.SignIn>
                  <Styled.MenuItemLink href="/entrar">
                    Entrar
                  </Styled.MenuItemLink>
                  <MyAccount />
                </Styled.SignIn>
              )}
            </Styled.HeaderInfo>
          </Styled.Header>
          <Styled.Menu>
            {nav.map((item: IMenuItem, index: number) => {
              if (!item.protected || (item.protected && loggedIn)) {
                return (
                  <Styled.MenuItem key={`idx${index}`}>
                    <Styled.MenuItemIcon>
                      {icons[item.icon]}
                    </Styled.MenuItemIcon>
                    <Styled.MenuItemLink href={item.url}>
                      {item.label}
                    </Styled.MenuItemLink>
                  </Styled.MenuItem>
                );
              }
            })}
            {loggedIn && (
              <Styled.MenuItem className="exit">
                <Styled.MenuItemIcon>
                  <Exit />
                </Styled.MenuItemIcon>
                <Styled.MenuItemLink href="#" onClick={() => signout()}>
                  Sair
                </Styled.MenuItemLink>
              </Styled.MenuItem>
            )}
          </Styled.Menu>
        </Styled.Content>
      )}
    </Drawer>
  );
};

export { Navigation };
