import React from "react";
import { Typography } from "@mui/material";

import * as Styled from "./styles";

const InstitutionalFooter: React.FC = () => {
  return (
    <Styled.Institutional>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        Institucional
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/quem-somos">Quem somos</a>
      </Typography>
      <Typography>
        <a href="https://besthunterwine.com.br/parceiro">Seja parceiro</a>
      </Typography>
    </Styled.Institutional>
  );
};

export { InstitutionalFooter };
