import React from "react";
import { Container, FormControl, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { HeaderInternal } from "src/components/HeaderInternal";
import { FormActions } from "src/components/FormActions";
import withAuthenticationRequired from "src/shared/hocs/with-authentication-required";
import { useForm, Controller } from "react-hook-form";
import { IUser } from "src/shared/types/User";
import { useIntl, defineMessages } from "react-intl";
import { getById, create, update, remove } from "src/shared/services/Partners";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import { ReactComponent as ErrorFieldIcon } from "src/assets/svg/ErrorField.svg";

import * as Styled from "src/shared/theme/form-styles";

const messages = defineMessages({
  required: { id: "global.required" },
  add: { id: "global.add" },
  edit: { id: "global.edit" },
  provider: { id: "provider.singular.title" },
  companyName: { id: "global.companyName" },
  responsible: { id: "global.responsible" },
  cellphone: { id: "global.cellphone" },
  email: { id: "global.email" },
  success: { id: "global.save.success" },
  error: { id: "global.save.error" },
  emailError: { id: "global.field.email" },
  document: { id: "provider.document" },
  password: { id: "global.password" },
  passwordField: { id: "global.field.password" },
});

const uri = "fornecedores";

const ProvidersPageForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();  
  let schema = yup.object().shape({
    document: yup.string().required(formatMessage(messages.required)),
    name: yup
      .string()
      .min(3, formatMessage(messages.required))
      .required(formatMessage(messages.required)),
    surname: yup.string().required(formatMessage(messages.required)),
    email: yup
      .string()
      .email(formatMessage(messages.emailError))
      .required(formatMessage(messages.required)),
    cellphone: yup
      .string()
      .min(3, formatMessage(messages.required))
      .required(formatMessage(messages.required))
  });

  if (!id) {
    schema =  schema.clone().shape({
      password: yup
      .string()
      .required(formatMessage(messages.required))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
        formatMessage(messages.passwordField)
      )})
  }

  
  const { openSnackbar } = useSnackbarConsumer();
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IUser>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: IUser) => {
    setLoading(true);
    values.sex = "MALE";
    values.role = "PARTNER";

    if (id) {
      const { status } = await update(values, Number(id));
      if (status === 200) {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    } else {
      const { status } = await create(values);

      if (status === 201) {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        setLoading(false);
        setTimeout(() => (window.location.href = `/${uri}`), 500);
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    }
  };

  const handleById = React.useCallback(
    async (id: number) => {
      try {
        const { status, data } = await getById(id);
        if (status === 200) {
          console.log(data);
          if (!data) window.location.href = `/${uri}`;
          reset(data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [reset]
  );

  const handleExclude = React.useCallback(async () => {
    try {
      const { status } = await remove(Number(id));
      if (status === 200 || status === 204) window.location.href = `/${uri}`;
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      setTimeout(() => {
        handleById(Number(id));
      }, 1500);
    }
  }, [handleById, id]);

  return (
    <Container maxWidth="sm">
      <Styled.Main>
        <HeaderInternal
          label={`${formatMessage(
            id ? messages.edit : messages.add
          )} ${formatMessage(messages.provider)}`}
          link={`/${uri}`}
        />
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Content>
            <FormControl className="formControl">
              <Controller
                name="document"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputMask
                    mask="99.999.999/9999-99"
                    label={formatMessage(messages.document)}
                    {...field}
                  >
                    {/*
              -      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore:next-line */}
                    {(inputProps: any) => (
                      <TextField
                        className={`inputField ${
                          errors.document ? "error-field" : ""
                        }`}
                        fullWidth
                        label={formatMessage(messages.responsible)}
                        size="small"
                        autoComplete="off"
                        helperText={errors.document?.message}
                        InputProps={{
                          endAdornment: errors.document && <ErrorFieldIcon />,
                        }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${errors.name ? "error-field" : ""}`}
                    fullWidth
                    label={formatMessage(messages.companyName)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.name?.message}
                    InputProps={{
                      endAdornment: errors.name && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="surname"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.surname ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.responsible)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.surname?.message}
                    InputProps={{
                      endAdornment: errors.surname && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="cellphone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputMask
                    mask="(99) 9999-9999"
                    label={formatMessage(messages.cellphone)}
                    {...field}
                  >
                    {/*
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore:next-line */}
                    {(inputProps: any) => (
                      <TextField
                        className={`inputField ${
                          errors.cellphone ? "error-field" : ""
                        }`}
                        fullWidth
                        label={formatMessage(messages.cellphone)}
                        size="small"
                        autoComplete="off"
                        helperText={errors.cellphone?.message}
                        InputProps={{
                          endAdornment: errors.cellphone && <ErrorFieldIcon />,
                        }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.surname ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.email)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.email?.message}
                    InputProps={{
                      endAdornment: errors.email && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            {!id && (
            <FormControl className="formControl">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextField
                      className={`inputField ${
                        errors.password ? "error-field" : ""
                      }`}
                      fullWidth
                      label={formatMessage(messages.password)}
                      size="small"
                      autoComplete="off"
                      helperText={formatMessage(messages.passwordField)}
                      InputProps={{
                        endAdornment: errors.password && <ErrorFieldIcon />,
                      }}
                      {...field}
                    />
                    <PasswordStrengthBar
                      password={field.value}
                      minLength={8}
                      scoreWordStyle={{
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                      }}
                      shortScoreWord={"Muito fraco"}
                      scoreWords={["Fraco", "Fraco", "Bom", "Ótimo", "Forte"]}
                    />
                  </>
                )}
              />
            </FormControl>
            )}
          </Styled.Content>

          <Styled.Actions>
            <FormActions
              id={id}
              loading={loading}
              onConfirmExclude={() => handleExclude()}
            />
          </Styled.Actions>
        </Styled.Form>
      </Styled.Main>
    </Container>
  );
};

export default withAuthenticationRequired(ProvidersPageForm);
