import React from "react";
import { IHarmonization } from "@shared/types/Harmonizations";
import { allParents } from "src/shared/services/Harmonizations";

export function useHarmonizations() {
  const [harmonizations, setHarmonizations] = React.useState<IHarmonization[]>(
    []
  );

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await allParents();
      if (status === 200) {
        setHarmonizations(data);
      }
      throw new Error();
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return { harmonizations };
}
