import React from "react";
import { Container } from "@mui/material";

import * as Styled from "./styles";
import { HelpFooter } from "./components/Help";
import { InstitutionalFooter } from "./components/Institutional";
import { SocialFooter } from "./components/Social";
import { PaymentFooter } from "./components/Payment";
import { SecuritySiteFooter } from "./components/SecuritySite";
import { ReactComponent as MiniLogoFooter } from "src/assets/svg/MiniLogoFooter.svg";

const Footer: React.FC = () => {
  return (
    <Styled.Content>
      <Container maxWidth="lg">
        <Styled.Contents>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "200px",
              marginRight: "80px",
            }}
          >
            <MiniLogoFooter />
          </div>
          <HelpFooter />
          <InstitutionalFooter />
          <SocialFooter />
          <PaymentFooter />
          <SecuritySiteFooter />
        </Styled.Contents>
      </Container>
    </Styled.Content>
  );
};

export { Footer };
