import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "src/shared/providers/auth";

import { LoginPage } from "../pages/auth/login";
import { ForgetPage } from "../pages/auth/forget";
import { NewPasswordPage } from "../pages/auth/new-password";

import { BePartnerPage } from "../pages/be-partner";

import ProvidersPage from "../pages/providers";
import ProvidersPageForm from "../pages/providers/form";

import UsersPage from "../pages/users";
import SignaturePage from "../pages/assinaturas";
import UsersAddPageForm from "../pages/users/form";

import { ProductsPage } from "../pages/products";
import { ProductAddPageForm } from "../pages/products/form";

import { PartnerProductsPage } from "../pages/partner-products";

import { OrdersPage } from "../pages/orders";
import { OrderPage } from "../pages/order";
import { UvasPage } from "../pages/uvas";
import { UvasAddPageForm } from "../pages/uvas/form";
import { PaisesPage } from "../pages/paises";
import { PaisesAddPageForm } from "../pages/paises/form";

const Router = () => {
  const { user } = useAuth();

  let ComponentHome: any = <LoginPage />;

  if (user) {
    if (user.role === "PARTNER") {
      ComponentHome = <OrdersPage />;
    }

    if (user.role === "ADMIN") {
      ComponentHome = <OrdersPage />;
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {user && user.role === "PARTNER" && (
          <>
            <Route
              path="/produtos-parceiro"
              element={<PartnerProductsPage />}
            />
            <Route path="/pedidos" element={<OrdersPage />} />
            <Route path="/pedidos/:id" element={<OrderPage />} />
          </>
        )}

        {user && user.role === "ADMIN" && (
          <>
            <Route path="/pedidos" element={<OrdersPage />} />
            <Route path="/pedidos/:id" element={<OrderPage />} />
            <Route path="/fornecedores" element={<ProvidersPage />} />
            <Route path="/fornecedores/form" element={<ProvidersPageForm />} />
            <Route
              path="/fornecedores/form/:id"
              element={<ProvidersPageForm />}
            />
            <Route path="/assinantes" element={<SignaturePage />} />
            <Route path="/usuarios" element={<UsersPage />} />
            <Route path="/usuarios/form" element={<UsersAddPageForm />} />
            <Route path="/usuarios/form/:id" element={<UsersAddPageForm />} />

            <Route path="/produtos" element={<ProductsPage />} />
            <Route path="/produtos/form" element={<ProductAddPageForm />} />
            <Route path="/produtos/form/:id" element={<ProductAddPageForm />} />


            <Route path="/uvas" element={<UvasPage />} />
            <Route path="/uvas/form" element={<UvasAddPageForm />} />
            <Route path="/uvas/form/:id" element={<UvasAddPageForm />} />

            <Route path="/paises" element={<PaisesPage />} />
            <Route path="/paises/form" element={<PaisesAddPageForm />} />
            <Route path="/paises/form/:id" element={<PaisesAddPageForm />} />

          </>
        )}

        <Route path="/login" element={<LoginPage />} />
        <Route path="/esqueci" element={<ForgetPage />} />
        <Route path="/nova-senha" element={<NewPasswordPage />} />
        <Route path="/seja-parceiro" element={<BePartnerPage />} />
        <Route path="/" element={ComponentHome} />
      </Routes>
    </BrowserRouter>
  );
};

export { Router };
