import {
  IUsersResponse,
  IUser,
  IUsersListResponse,
} from "src/shared/types/User";
import axiosInstance from "src/shared/services/api";

const uri = "partner";

export const list = async (page: number = 1): Promise<IUsersListResponse> => {
  return axiosInstance.get(`/${uri}/list/${page}`);
};

export const getById = async (id: number): Promise<IUsersResponse> => {
  return axiosInstance.get(`/${uri}/${id}`);
};

export const create = async (values: IUser): Promise<IUsersResponse> => {
  return axiosInstance.post(`/${uri}`, values);
};

export const update = async (
  values: IUser,
  id: number
): Promise<IUsersResponse> => {
  return axiosInstance.put(`/${uri}/${id}`, values);
};

export const remove = async (id: number): Promise<IUsersResponse> => {
  return axiosInstance.delete(`${uri}/${id}`);
};
