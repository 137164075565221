import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Resume = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
`;

export const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  p {
    text-align: center;
    max-width: 350px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Actions = styled.div`
  display: flex;
  margin: 24px 0;
  .buttonActions {
    display: flex;
    width: 50%;

    .MuiButton-root {
      width: 90%;
    }
    .secondary {
      background-color: ${colors.white};
      color: ${colors.rose};
      border: 1px solid ${colors.rose} !important;
      &:hover {
        background-color: ${colors.rose};
        color: ${colors.white};
      }
    }
  }
  .right {
    justify-content: flex-end;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 10px;
  color: ${colors.lightGray};
  padding: 16px 16px;
  margin-top: 16px;
  .inputField {
    margin-top: 32px;
  }
  .MuiFormControl-root {
    border-color: transparent !important;
  }
`;




export const ContentMargin = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  border-radius: 10px;
  color: ${colors.lightGray};
  padding: 10px 16px;
  margin-top: 16px;
  font-size: 12px !important;
  justify-content: space-between;
  .inputField {
    margin-top: 12px;
  }
  .MuiFormControl-root {
    border-color: transparent !important;
  }
`;

export const ContentNew = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  border-radius: 10px;
  color: ${colors.lightGray};
  padding: 0 16px;
  font-size: 12px !important;
  .inputField {
    margin-top: 12px;
  }
  .MuiFormControl-root {
    border-color: transparent !important;
  }
`;


export const ZipcodeBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  & > .MuiFormControl-root {
    width: 48%;
  }
`;

export const Title = styled.div`
  display: flex;
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.darkGray};
  }
`;

export const CheckboxBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const CheckboxTitle = styled.div`
  display: flex;
  padding: 10px 0;
  margin: 20px 0;
  border-bottom: 1px solid ${colors.partnersGray};
  p {
    font-weight: 800;
  }
`;
export const CheckboxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.unique {
    padding: 10px 0;
    margin: 20px 0;
  }
`;
