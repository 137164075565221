import React from "react";

import {
  FormControl,
  Typography,
  Container,
  CircularProgress,
  Box,
  Modal,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useIntl, defineMessages } from "react-intl";

import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { HeaderInternal } from "src/components/HeaderInternal";
import { Paginate } from "src/components/Paginate";
import { create, list, update } from "src/shared/services/PartnerProducts";
import { IMeta } from "src/shared/types/Pagination";
import { IPartnerProduct } from "@shared/types/PartnerProducts";
import { NoResultsList } from "src/components/NoResultsList";
import { PartnerProductItem } from "src/components/PartnerProductItem";
import { getById } from "src/shared/services/PartnerProducts";
import { all } from "src/shared/services/Products";

import * as Styled from "src/shared/theme/form-styles";
import { StyledModal } from "./styles";
import { IProduct } from "@shared/types/Products";

const uri = "produtos-parceiro";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface IProductOption {
  label: string;
  value: number;
}

const messages = defineMessages({
  success: { id: "global.save.success" },
  error: { id: "global.save.error" },
  save: { id: "global.save" },
});

const PartnerProductsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");

  const { openSnackbar } = useSnackbarConsumer();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IPartnerProduct[]>([]);
  const [meta, setMeta] = React.useState<IMeta>({ page: 1, totalPages: 1 });
  const [open, setOpen] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<IPartnerProduct>(
    {} as IPartnerProduct
  );
  const [productsOptions, setProductsOptions] = React.useState<
    IProductOption[]
  >([]);

  const handleData = React.useCallback(async () => {
    try {
      setLoading(true);

      const { status, data: products } = await list(Number(page || 1));
      if (status === 200) {
        const orderByName = products.data.sort((a: IPartnerProduct, b: IPartnerProduct) => {
          if (a.product === undefined || b.product === undefined) return 0;
          if (a.product === null || b.product === null) return 0
          return a.product.name.localeCompare(b.product.name);
        });
        await setData(products.data);
        await setMeta(products.meta);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [page, setData]);

  const handleProducts = React.useCallback(async () => {
    try {
      const { status, data: products } = await all();
      if (status === 200) {
        const prds: IProductOption[] = [];
        products.forEach((product: IProduct) =>
          prds.push({ label: product.name, value: product.id || 0 })
        );
        setProductsOptions(prds);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoadingSave(true);
    let resp;

    const data: IPartnerProduct = {
      productId: editData.productId,
      availableQuantity: editData.availableQuantity,
      priceFrom: editData.priceFrom,
      price: editData.price,
    };

    if (editData.id) {
      resp = await update(data, editData.id);
    } else {
      resp = await create(data);
    }

    if ([200, 201].includes(resp.status)) {
      openSnackbar({
        message: formatMessage(messages.success),
        severity: "success",
      });
      setTimeout(() => {
        handleData();
        setEditData({} as IPartnerProduct);
        setOpen(false);
      }, 500);
    }

    setLoadingSave(false);
  };

  const handleEdit = async (id: number) => {
    setOpen(true);
    setLoadingData(true);
    const { status, data: dataEdit } = await getById(id);
    if (status === 200) {
      dataEdit.productLabelValue = {
        label: (dataEdit.product && dataEdit.product.name) || "",
        value: dataEdit.id || 0,
      };
      setEditData(dataEdit);
    }
    setLoadingData(false);
  };

  const handleInsert = () => {
    setEditData({} as IPartnerProduct);
    setOpen(true);
    setLoadingData(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({} as IPartnerProduct);
  };

  React.useEffect(() => {
    handleData();
    handleProducts();
  }, [handleData, handleProducts]);

  return (
    <Container maxWidth="sm">
      <Styled.Main>
        <HeaderInternal
          label="Produtos"
          add={false}
          //onClick={() => handleInsert()}
          uri={'/pedidos'}
        />        
        {data && data.length >= 1 ? (
          <>
            {!loading ? (
              <>
                <Styled.List>
                  {data.map((item: IPartnerProduct, index: number) => {
                    if (item.product) {
                      return (
                        <PartnerProductItem
                          key={`idx${index}`}
                          partnerProduct={item}
                          onEdit={() => handleEdit(item.id || 0)}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </Styled.List>
                <Paginate
                  actualPage={meta.page}
                  totalPages={meta.totalPages}
                  uri={uri}
                />
              </>
            ) : (
              <Styled.Loading>
                <CircularProgress size={30} />
              </Styled.Loading>
            )}
          </>
        ) : (
          <Styled.NoResults>
            <NoResultsList uri={uri} />
          </Styled.NoResults>
        )}
      </Styled.Main>

      <div>
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <StyledModal>
              {loadingData ? (
                <CircularProgress size={80} />
              ) : (
                <div className="content">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="title"
                  >
                    {editData.product && editData.product.name}
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <FormControl>
                      <Autocomplete
                        disablePortal
                        options={productsOptions}
                        disabled={editData.id ? true : false}
                        value={editData.productLabelValue}
                        renderInput={(params) => (
                          <TextField {...params} label="Produto" />
                        )}
                        onChange={(_, selected) => {
                          if (selected) {
                            setEditData({
                              ...editData,
                              ...{
                                productLabelValue: selected,
                                productId: selected.value || 0,
                              },
                            });
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="number"
                        label="Estoque"
                        name="availableQuantity"
                        value={editData.availableQuantity}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            [e.target.name]: Number(e.target.value),
                          })
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <NumericFormat
                        label="Preço de"
                        value={editData.priceFrom}
                        prefix={"R$"}
                        customInput={TextField}
                        decimalSeparator=","
                        onValueChange={(values, e) => {
                          setEditData({
                            ...editData,
                            priceFrom: values.floatValue || 0,
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <NumericFormat
                        label="Preço"
                        value={editData.price}
                        prefix={"R$"}
                        customInput={TextField}
                        decimalSeparator=","
                        onValueChange={(values, e) => {
                          setEditData({
                            ...editData,
                            price: values.floatValue || 0,
                          });
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <Button type="submit" disabled={loadingSave}>
                        {formatMessage(messages.save)}
                      </Button>
                    </FormControl>
                  </form>
                </div>
              )}
            </StyledModal>
          </Box>
        </Modal>
      </div>
    </Container>
  );
};

export { PartnerProductsPage };
