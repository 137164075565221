import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "src/assets/svg/Edit.svg";
import { IPartnerProduct } from "src/shared/types/PartnerProducts";

import * as Styled from "./styles";

interface IProps {
  partnerProduct: IPartnerProduct;
  onEdit: () => void;
}

const PartnerProductItem: React.FC<IProps> = ({ partnerProduct, onEdit }) => {
  const { product } = partnerProduct;

  return (
    <Styled.Item>
      <Styled.Image center={true}>
        {product && product.image && <img src={product.image} alt="" />}
      </Styled.Image>
      <Styled.Information>
        <Styled.Title>
          <Typography>{(product && product.name) || "Não definido"}</Typography>
        </Styled.Title>
        <Styled.Subtitle>
          <Typography>
            Estoque: {partnerProduct.availableQuantity || 0}
          </Typography>
        </Styled.Subtitle>
        <Styled.Action onClick={() => onEdit()}>
          <EditIcon />
        </Styled.Action>
      </Styled.Information>
    </Styled.Item>
  );
};

export { PartnerProductItem };
