import React from "react";
import "./global.css";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import IntlProvider from "src/shared/providers/Intl";
import { AuthProvider } from "src/shared/providers/auth";
import { theme } from "./config/theme";
import { Router } from "./config/router";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import SnackbarProvider from "src/shared/providers/Snackbar";

const App = () => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <SCThemeProvider theme={theme}>
          <IntlProvider>
            <SnackbarProvider>
              <AuthProvider>
                <Header />
                <Router />
                <Footer />
              </AuthProvider>
            </SnackbarProvider>
          </IntlProvider>
        </SCThemeProvider>
      </MuiThemeProvider>
    </>
  );
};

export { App };
