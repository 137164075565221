import React from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ReactComponent as Logo } from "src/assets/svg/logomini.svg";
import { useForm } from "react-hook-form";
import { useIntl, defineMessages } from "react-intl";
import { IAuthLogin } from "src/shared/types/Auth";
import { useAuth } from "src/shared/providers/auth";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";

import * as Styled from "src/shared/theme/login";
import { ROUTE_AFTER_LOGIN } from "src/shared/constants/auth";

const messages = defineMessages({
  title: { id: "login.title" },
  welcome: { id: "login.welcome" },
  required: { id: "global.required" },
  signin: { id: "global.signin" },
  forget: { id: "login.forget" },
});

const LoginPage = () => {
  const { openSnackbar } = useSnackbarConsumer();
  const { sigin, loading, setLoading, isLogged } = useAuth();

  if (isLogged) window.location.href = "/";

  const { formatMessage } = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAuthLogin>();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const onSubmit = async (values: IAuthLogin) => {
    setLoading(true);
    const loggedIn: boolean = await sigin(values);

    if (loggedIn) {
      window.location.href = ROUTE_AFTER_LOGIN;
    } else {
      setLoading(false);
      openSnackbar({
        message: "Dados inválidos, tente novamente.",
        severity: "error",
      });
    }
  };

  return (
    <Styled.Main>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.BoxLogin>
        <Styled.Login>
          <Styled.Title>
            <Typography variant="h1">
              {formatMessage(messages.title)}
            </Typography>
          </Styled.Title>
          <Styled.Subtitle>
            <Typography>{formatMessage(messages.welcome)}</Typography>
          </Styled.Subtitle>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <TextField
                fullWidth
                label="Email"
                size="small"
                autoComplete="off"
                {...register("email", { required: true })}
                error={Boolean(errors.email)}
                helperText={
                  errors.email?.type === "required" &&
                  formatMessage(messages.required)
                }
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                label="Senha"
                size="small"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                {...register("password", { required: true })}
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.type === "required" &&
                  formatMessage(messages.required)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button type="submit" disabled={loading}>
              {loading ? (
                <CircularProgress size={30} color="secondary" />
              ) : (
                formatMessage(messages.signin)
              )}
            </Button>
            <Styled.Actions>
              <Styled.Remeber>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Lembrar"
                  />
                </FormGroup>
              </Styled.Remeber>
              <Styled.Forget>
                <Typography>
                  <a href="/esqueci">{formatMessage(messages.forget)}</a>
                </Typography>
              </Styled.Forget>
            </Styled.Actions>
          </Styled.Form>
          <Styled.Footer>
            <Typography>
              Não é um fornecedor ainda? <a href="/">Entre em contato</a>
            </Typography>
          </Styled.Footer>
        </Styled.Login>
      </Styled.BoxLogin>
    </Styled.Main>
  );
};

export { LoginPage };
