import styled from "styled-components";
import { Box } from "@mui/material";
import colors from "src/shared/theme/colors";

export const Content = styled(Box)`
  width: 292px;
  height: 100%;
  background-color: ${colors.wine};
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
`;
export const Header = styled.div`
  display: flex;
`;
export const HeaderAvatar = styled.div`
  width: 80px;
  height: 80px;
`;
export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .MuiTypography-root {
    color: ${colors.white};
    font-size: 24px !important;
    font-weight: 400;
    &:last-child {
      font-weight: 700;
    }
  }
`;
export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 90px;
  position: relative;
`;
export const MenuItem = styled.li`
  display: flex;
  padding: 12px 0;
  /* &.exit {
  } */
`;
export const MenuItemIcon = styled.div`
  display: flex;
`;
export const MenuItemLink = styled.a`
  display: inline-flex;
  color: ${colors.white};
  font-weight: 400;
  font-size: 16px !important;
  line-height: 150%;
  font-family: Montserrat;
  text-decoration: none;
  margin-left: 25px;
  &:hover {
    text-decoration: underline;
  }
`;

export const SignIn = styled.div`
  display: flex;
  position: absolute;
  right: 28px;
  top: 40px;
  a {
    margin-right: 12px;
  }
`;
