import React from "react";
import { Typography } from "@mui/material";
import { useIntl, defineMessages } from "react-intl";
import { ReactComponent as Danger } from "src/assets/svg/Danger.svg";

const messages = defineMessages({
  noResultsFound: { id: "global.no.results.found" },
  noResultsNewRow: { id: "global.no.results.new.row" },
});

interface IProps {
  uri: string;
}

const NoResultsList: React.FC<IProps> = ({ uri }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Danger />
      <Typography>
        {formatMessage(messages.noResultsFound)}{" "}
        <a href={`${uri}/form`}>{formatMessage(messages.noResultsNewRow)}</a>
      </Typography>
    </>
  );
};

export { NoResultsList };
