import React, { useEffect, useMemo } from "react";
import {
  Container,
  FormControl,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { FormActions } from "src/components/FormActions";
import { getById, create, update } from "src/shared/services/Products";
import { list } from "src/shared/services/Partners";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";

import { useHarmonizations } from "src/shared/hooks/useHarmonizations";
import { useOccasions } from "src/shared/hooks/useOccasions";
import { useCategories } from "src/shared/hooks/useCategories";
import { all } from 'src/shared/services/Categories'
import { HeaderInternal } from "src/components/HeaderInternal";
import { GalleryForm } from "src/components/GalleryForm";
import { IProduct } from "src/shared/types/Products";
import { ReactComponent as ErrorFieldIcon } from "src/assets/svg/ErrorField.svg";
import { IHarmonization } from "src/shared/types/Harmonizations";
import { IOccasion } from "src/shared/types/Occasions";
import { ICategory } from "src/shared/types/Categories";

import * as Styled from "src/shared/theme/form-styles";
import { countries } from "src/shared/config/countries";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import slugify from '@sindresorhus/slugify';
import {Buffer} from 'buffer';

const messages = defineMessages({
  required: { id: "global.required" },
  add: { id: "global.add" },
  edit: { id: "global.edit" },
  productName: { id: "product.name" },
  productSku: { id: "product.sku" },
  productCountry: { id: "product.country" },
  productHarvest: { id: "product.harvest" },
  productRegion: { id: "product.region" },
  productProducer: { id: "product.producer" },
  productDescription: { id: "product.description" },
  productDetailedDescription: { id: "product.detailed.description" },
  productClosureType: { id: "product.closure.type" },
  productTemperature: { id: "product.temperature" },
  productVolumeMl: { id: "product.volume" },
  productPercentageAlcoholContent: { id: "product.percentage.alcohol" },
  productPotentialYearGuard: { id: "product.potential.year.guard" },
  productSweetnessLevel: { id: "product.sweetness.level" },
  productAcidityLevel: { id: "product.acidity.level" },
  productTanninLevel: { id: "product.tannin.level" },
  productVisualDescription: { id: "product.visual.description" },
  productMouthDescription: { id: "product.mouth.description" },
  productNoseDescription: { id: "product.nose.description" },
  productHarmonizations: { id: "product.harmonizations" },
  productOccasions: { id: "product.occasions" },
  productCategories: { id: "product.categories" },
  productGrape: { id: "product.grape" },
  productImage: { id: "product.image" },
  success: { id: "global.save.success" },
  error: { id: "global.save.error" },
});

const uri = "produtos";

const ProductAddPageForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const [paises, setPaises] = React.useState<any>([])

  useEffect(() => {
    all().then((data) => {
      const x = (data.data?.filter((item: any) => item.UID === 'PAISES'))
      if (x.length>0){
        setPaises(x[0].categories)
      }
    })
    list().then((data) => {
      setPartners(data.data?.data);
    })
  },[])
  const schema = yup.object().shape({
    name: yup
      .string()
      .min(3, formatMessage(messages.required))
      .required(formatMessage(messages.required)),
    sku: yup.string().required(formatMessage(messages.required)),
  });

  const { id } = useParams();
  const { openSnackbar } = useSnackbarConsumer();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const { harmonizations } = useHarmonizations();
  const { occasions } = useOccasions();
  const { categories } = useCategories();

  const config = {
    region: process.env.AWS_REGION || 'us-east-1',
    s3ForcePathStyle: true,
    //logger: console,
    credentials: {
        accessKeyId: process.env.AWS_ACCESS_KEY_ID || 'AKIATIHLET53USL5YQ7P',
        secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || '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',
    },
  }

  const client = new S3Client({...config});

  const fileUpdate = async (file: any) => {
    const name = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let base64Data = Buffer.from(
      file.replace(/^data:.+;base64,/, ""),
      'base64',
  );
  if (base64Data.length / 1024 < 3 ) return '';
  let mime = file.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (!mime || !mime.length || mime.length < 1) return '';
    const type = file.split(';')[0].split('/')[1];
    const command = new PutObjectCommand({
      Bucket: "static.besthunterwine.com.br",
      Key: `produtos/${name}.${type}`,
      Body: base64Data,
      ContentType: mime[1],
      ACL: 'public-read',
    });
    try {
      const resp = await client.send(command);
      return `${name}.${type}`
    } catch (err) {
      console.log(err);
      return null
    }
  }
  
  interface IProductForm {
    partners: any;
    PartnerhasProduct:any;
    stock: number;
    price: number;
    from: number;
    selected: number;
  }

  type IFormProd = IProduct & IProductForm;
  const [partners, setPartners] = React.useState<any>([]);
  const [prodPartner, setProdPartner] = React.useState<any>([]);
  const [harmonizationIds, setHarmonizationIds] = React.useState<number[]>([]);
  const [occasionIds, setOccasionIds] = React.useState<number[]>([]);
  const [categoryUUID, setCategoryUUID] = React.useState<string>('');
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<IFormProd>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: IFormProd) => {
    setLoading(true);

    if (values.detailedDescription === "" || values.detailedDescription === null) {
      alert('Descrição detalhada é obrigatória')
      setLoading(false);
      return; 
    }

    if (values.description === "" || values.description === null) {
      alert('Descrição é obrigatória')
      setLoading(false);
      return; 
    }

    if (values.name === "" || values.name === null) {
      alert('Nome é obrigatório')
      setLoading(false);
      return; 
    }

    if (id === null && categoryUUID === "" || categoryUUID === null) {
      alert('Escolha pelo menos um Tipo na seção Categorias')
      setLoading(false);
      return; 
    }



    delete values.id;
    delete values.productId;
    delete values.PartnerhasProduct
    delete values.partners;
    delete values.createdAt;
    delete values.updatedAt;
    delete values.products;
    delete values.productHasHarmonizationId;
    delete values.productHasOccasionId;
    delete values.productHasCategoryId;
    delete values.type;

    values.points = Number(values.points || 0);
    values.harvest = Number(values.harvest);
    values.volumeMl = Number(values.volumeMl);
    values.percentageAlcoholContent = Number(values.percentageAlcoholContent);
    values.potentialYearGuard = Number(values.potentialYearGuard);
    if (categoryUUID !== '') values.type = categoryUUID;

    if (id) {
      const { status } = await update(values, Number(id));
      if (status === 200) {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    } else {
      const { status } = await create(values);

      if (status === 201) {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        setTimeout(() => (window.location.href = `/${uri}`), 500);
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    }
  };

  const handleById = React.useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        let response = await getById(id);        
        let {status } = response;
        const data = response.data as IFormProd;
        if (status === 200) {

          if (!data) window.location.href = `/${uri}`;
          data.harmonizationIds = [];
          data.occasionIds = [];
          data.categoryIds = [];

          const form = data as IFormProd;

          if (form.partners && form.partners.length > 0) {
            setPartners(form.partners);
          }
          if (data.productHasHarmonizationId) {
            data.productHasHarmonizationId.forEach((value) =>
              data.harmonizationIds?.push(value.harmonizationId)
            );
          }

          if (form.PartnerhasProduct && form.PartnerhasProduct.length > 0) {
            setProdPartner(form.PartnerhasProduct[0]);
            data.stock = Number(form.PartnerhasProduct[0].availableQuantity) || 0;
            data.price = Number(form.PartnerhasProduct[0].price) || 0;
            data.from =  Number(form.PartnerhasProduct[0].priceFrom) || 0;
            data.selected = form.PartnerhasProduct[0].partnerId;
          }

          
          if (data.productHasOccasionId) {
            data.productHasOccasionId.forEach((value) =>
              data.occasionIds?.push(value.occasionId)
            );
          }

          if (data.productHasCategoryId) {
            data.productHasCategoryId.forEach((value) =>
              data.categoryIds?.push(value.categoryId)
            );
          }
          console.log(data)
          reset(data);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [reset]
  );


  React.useEffect(() => {
    if (id) {
      setLoading(true);
      setTimeout(() => {
        handleById(Number(id));
      }, 1500);
    }
  }, [handleById, id]);

  return loading ? (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <Typography variant="h4">Carregando...</Typography>
      </div>
  ) : (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal
          label={`${id ? "Editar" : "Adicionar"} Produto`}
          link={`/${uri}`}
        />
        {/* <GalleryForm onChange={(values: any) => console.log(values)} /> */}
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.Title>
            <Typography>Ficha técnica</Typography>
          </Styled.Title>
          <Styled.Content style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: '1rem'
          }}>
            <FormControl className="formControl">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.name ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productName)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.name?.message}
                    InputProps={{
                      endAdornment: errors.name && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="sku"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${errors.sku ? "error-field" : ""}`}
                    fullWidth
                    label={formatMessage(messages.productSku)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.sku?.message}
                    InputProps={{
                      endAdornment: errors.sku && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="image"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    {field.value !== "" && (
                      <img src={field.value} alt="product" style={{
                        maxWidth: '100px'
                      }} />
                    )}
                    <Typography>Imagem da Capa</Typography>
                    <input type="file" onChange={(e) => {
                      const file = e.target.files![0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = () => {
                        const base64data = reader.result;
                        if (base64data) {
                          setLoading(true);
                          fileUpdate(base64data)
                          .then((data) => {
                            if (data) {
                              setValue("image", `https://s3.amazonaws.com/static.besthunterwine.com.br/produtos/${data}`)
                              field.onChange(`https://s3.amazonaws.com/static.besthunterwine.com.br/produtos/${data}`);
                            }
                            setLoading(false);
                          })
                        }
                      }
                    }} />
                    <TextField
                      className={`inputField ${
                        errors.image ? "error-field" : ""
                      }`}
                      style={{
                        display: 'none'
                      }}
                      fullWidth
                      label={formatMessage(messages.productImage)}
                      size="small"
                      autoComplete="off"
                      helperText={errors.image?.message}
                      InputProps={{
                        endAdornment: errors.image && <ErrorFieldIcon />,
                      }}
                      {...field}
                    />
                  </>
                )}
              />
            </FormControl>

            <FormControl className="formControl">
              <Controller
                name="harvest"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${errors.name ? "error-field" : ""}`}
                    fullWidth
                    label={formatMessage(messages.productHarvest)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.harvest?.message}
                    InputProps={{
                      endAdornment: errors.harvest && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.region ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productRegion)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.region?.message}
                    InputProps={{
                      endAdornment: errors.region && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="producer"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.producer ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productProducer)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.producer?.message}
                    InputProps={{
                      endAdornment: errors.producer && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.description ? "error-field" : ""
                    }`}
                    fullWidth
                    multiline
                    rows={5}
                    label={formatMessage(messages.productDescription)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.description?.message}
                    InputProps={{
                      endAdornment: errors.description && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="detailedDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.detailedDescription ? "error-field" : ""
                    }`}
                    fullWidth
                    multiline
                    rows={5}
                    label={formatMessage(messages.productDetailedDescription)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.detailedDescription?.message}
                    InputProps={{
                      endAdornment: errors.detailedDescription && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="grape"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.grape ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productGrape)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.grape?.message}
                    InputProps={{
                      endAdornment: errors.grape && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="categoryCountryId"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <>
                    <Select
                      className={`inputField ${
                        errors.country ? "error-field" : ""
                      }`}
                      fullWidth
                      placeholder={formatMessage(messages.productCountry)}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      label={formatMessage(messages.productCountry)}
                      {...field}
                    >
                      {(paises).map((country: any) => (
                        <MenuItem key={`ctr${country.id}`} value={country.id}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>            
            <FormControl>
              <Controller
                name="closureType"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.closureType ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productClosureType)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.closureType?.message}
                    InputProps={{
                      endAdornment: errors.closureType && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="temperature"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.temperature ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productTemperature)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.temperature?.message}
                    InputProps={{
                      endAdornment: errors.temperature && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="volumeMl"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.volumeMl ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productVolumeMl)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.volumeMl?.message}
                    InputProps={{
                      endAdornment: errors.volumeMl && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="percentageAlcoholContent"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.percentageAlcoholContent ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(
                      messages.productPercentageAlcoholContent
                    )}
                    size="small"
                    autoComplete="off"
                    helperText={errors.percentageAlcoholContent?.message}
                    InputProps={{
                      endAdornment: errors.percentageAlcoholContent && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="potentialYearGuard"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.potentialYearGuard ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productPotentialYearGuard)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.potentialYearGuard?.message}
                    InputProps={{
                      endAdornment: errors.potentialYearGuard && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="sweetnessLevel"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.sweetnessLevel ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productSweetnessLevel)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.sweetnessLevel?.message}
                    InputProps={{
                      endAdornment: errors.sweetnessLevel && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="acidity_level"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.acidity_level ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productAcidityLevel)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.acidity_level?.message}
                    InputProps={{
                      endAdornment: errors.acidity_level && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="tanninLevel"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${
                      errors.tanninLevel ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productTanninLevel)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.tanninLevel?.message}
                    InputProps={{
                      endAdornment: errors.tanninLevel && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="points"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="text"
                    className={`inputField ${
                      errors.points ? "error-field" : ""
                    }`}
                    fullWidth
                    label={'Pontuação BHW'}
                    size="small"
                    autoComplete="off"
                    helperText={errors.points?.message}
                    InputProps={{
                      endAdornment: errors.points && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="pointsIndex"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    className={`inputField ${
                      errors.pointsIndex ? "error-field" : ""
                    }`}
                    fullWidth
                    label={'Avaliador'}
                    size="small"
                    autoComplete="off"
                    helperText={errors.pointsIndex?.message}
                    InputProps={{
                      endAdornment: errors.points && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </Styled.Content>
          {
          <>                 
          <Styled.Title>
            <Typography>Parceiro, Estoque e Preço</Typography>
          </Styled.Title>
          <Styled.Content style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: '1rem'
          }}>
            <FormControl>
              <Controller
                name="selected"
                control={control}
                defaultValue={prodPartner.length > 0 ? prodPartner.partnerId : ''}
                render={({ field }) => (
                  <>
                    <label style={{
                      display: 'flex',
                      position: 'absolute',
                      bottom: '2rem',
                      fontFamily: 'Montserrat !important',
                      fontSize: '1rem',
                    }}>Parceiro</label>
                    {partners !== undefined && 
                    <Select
                      className={`inputField ${
                        errors.country ? "error-field" : ""
                      }`}
                      fullWidth
                      placeholder={'Parceiro'}
                      size="small"
                      autoComplete="off"
                      variant="outlined"
                      label={formatMessage(messages.productCountry)}
                      {...field}
                    >
                      {(partners).map((item: any) => (
                        <MenuItem key={`partner${item.id}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    }
                  </>
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="stock"                
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    className={`inputField`}
                    type="number"
                    fullWidth
                    label={('Estoque')}
                    size="small"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="price"
                control={control}
                defaultValue={0.00}
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.price ? "error-field" : ""
                    }`}
                    fullWidth
                    label={'Preço de venda:'}
                    size="small"
                    type="number"
                    autoComplete="off"
                    helperText={errors.price?.message}
                    InputProps={{
                      endAdornment: errors.price && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="from"
                control={control}
                defaultValue={0.00}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField`}
                    fullWidth
                    label={'De:'}
                    size="small"
                    autoComplete="off"
                    helperText={errors.from?.message}
                    InputProps={{
                      endAdornment: errors.from && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </Styled.Content>
          </>
}
          <Styled.Title style={{
            marginTop: '1rem'
          }}>
              <Typography>Descrições</Typography>
          </Styled.Title>
          <Styled.Content style={{
            display: 'grid',
            gridTemplate: `1fr / 1fr 1fr`,
            gap: '1rem'
          }}>
            <FormControl>
              <Controller
                name="visualDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.visualDescription ? "error-field" : ""
                    }`}
                    fullWidth
                    multiline
                    rows={5}
                    label={formatMessage(messages.productVisualDescription)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.visualDescription?.message}
                    InputProps={{
                      endAdornment: errors.visualDescription && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="mouthDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.mouthDescription ? "error-field" : ""
                    }`}
                    fullWidth
                    multiline
                    rows={5}
                    label={formatMessage(messages.productMouthDescription)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.mouthDescription?.message}
                    InputProps={{
                      endAdornment: errors.mouthDescription && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="noseDescription"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.noseDescription ? "error-field" : ""
                    }`}
                    fullWidth
                    multiline
                    rows={5}
                    label={formatMessage(messages.productNoseDescription)}
                    size="small"
                    autoComplete="off"
                    helperText={errors.noseDescription?.message}
                    InputProps={{
                      endAdornment: errors.noseDescription && (
                        <ErrorFieldIcon />
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </Styled.Content>
          <Styled.Title>
              <Typography>
                {formatMessage(messages.productHarmonizations)}
              </Typography>
            </Styled.Title>
          <Styled.ContentNew>
            {harmonizations &&
              harmonizations.map(
                (harmonization: IHarmonization, index: number) => (
                  <Styled.CheckboxBox key={`idx${index}`}>
                    <Styled.CheckboxTitle>
                      <Typography>{harmonization.name}</Typography>
                    </Styled.CheckboxTitle>
                    <Styled.CheckboxContent>                      
                      {harmonization.harmonizations &&
                        harmonization.harmonizations.map(
                          (
                            harmonizationChild: IHarmonization,
                            indexChild: number
                          ) => (
                            <Controller
                              name="harmonizationIds"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => {
                                return (
                                  <FormControlLabel
                                    checked={
                                      field.value &&
                                      field.value.includes(
                                        harmonizationChild.id
                                      )
                                    }
                                    key={`idxc${indexChild}`}
                                    control={<Checkbox />}
                                    label={harmonizationChild.name}
                                    value={field.value}
                                    onChange={(e, value) => {
                                      console.log(harmonizationChild)
                                      const harmonizationIds: number[] =
                                        field.value || [];
                                      const harmId: number =
                                        harmonizationChild.id;
                                      if (value) {
                                        harmonizationIds.push(harmId);
                                      } else {
                                        harmonizationIds.splice(
                                          harmonizationIds.indexOf(harmId),
                                          1
                                        );
                                      }
                                      setValue(
                                        "harmonizationIds",
                                        harmonizationIds
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          )
                        )}
                    </Styled.CheckboxContent>
                  </Styled.CheckboxBox>
                )
              )}
          </Styled.ContentNew>
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem'
            }}
          >
            <Styled.Content>
              <Styled.Title>
                <Typography>
                  {formatMessage(messages.productOccasions)}
                </Typography>
              </Styled.Title>
              <Styled.CheckboxBox>
                <Styled.CheckboxContent className="unique">
                  {occasions &&
                    occasions.map((occasion: IOccasion, index: number) => (
                      <Controller
                        key={`idx${index}`}
                        name="occasionIds"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <FormControlLabel
                            key={`idxc${index}`}
                            checked={
                              field.value && field.value.includes(occasion.id)
                            }
                            control={<Checkbox />}
                            label={occasion.name}
                            value={field.value}
                            onChange={(e, value) => {
                              const occasionIds: number[] = field.value || [];
                              const occaId: number = occasion.id;
                              if (value) {
                                occasionIds.push(occaId);
                              } else {
                                occasionIds.splice(
                                  occasionIds.indexOf(occaId),
                                  1
                                );
                              }
                              setValue("occasionIds", occasionIds);
                            }}
                          />
                        )}
                      />
                    ))}
                </Styled.CheckboxContent>
              </Styled.CheckboxBox>
            </Styled.Content>

            <Styled.Content>
              <Styled.Title>
                <Typography>
                  {formatMessage(messages.productCategories)}
                </Typography>
              </Styled.Title>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              >
              {categories &&
                categories.map((category: ICategory, index: number) => (
                  <Styled.CheckboxBox key={`idx${index}`}>
                    <Styled.CheckboxTitle>
                      <Typography>{category.name}</Typography>
                    </Styled.CheckboxTitle>
                    <Styled.CheckboxContent>
                      {category.categories &&
                        category.categories.map(
                          (categoryChild: ICategory, indexChild: number) => (
                            <Controller
                              key={`idxd${indexChild}`}
                              name="categoryIds"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <FormControlLabel
                                  key={`idxc${indexChild}`}
                                  checked={
                                    field.value &&
                                    field.value.includes(categoryChild.id)
                                  }
                                  control={<Checkbox />}
                                  label={categoryChild.name}
                                  value={field.value}
                                  onChange={(e, value) => {                                    
                                    const categoryIds: number[] =
                                      field.value || [];
                                    const catId: number = categoryChild.id;
                                    if (value) {
                                      categoryIds.push(catId);
                                      let Harmonizations = []
                                      
                                      /** */
                                      switch (categoryChild.UID){
                                        case 'ESPUMANTES':
                                          setCategoryUUID('ESPUMANTES')
                                          setValue("occasionIds", [1,2,3,4]);                                          
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 1 || x.id === 3 || x.id === 4 || x.id === 5 || x.id === 6 || x.id === 7 || x.id === 8){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,1,3,4,5,6,7,8]);
                                          break;
                                        case 'VINHOS_BRANCOS_LEVES':
                                          setCategoryUUID('VINHOS_BRANCOS_LEVES')
                                          setValue("occasionIds", [1,2,3,4]);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 4 || x.id === 5 || x.id === 6 || x.id === 7 || x.id === 8){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,4,5,6,7,8]);
                                          break;
                                        case 'VINHOS_BRANCOS_AROMATICOS':
                                          setCategoryUUID('VINHOS_BRANCOS_AROMATICOS')
                                          setValue("occasionIds", []);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 1 || x.id === 3 || x.id === 6 || x.id === 7 || x.id === 8 || x.id === 9){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,1,3,6,7,8,9]);
                                          break;
                                        case 'VINHOS_BRANCOS_ENCORPADOS':
                                          setCategoryUUID('VINHOS_BRANCOS_ENCORPADOS')
                                          setValue("occasionIds", []);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 1 || x.id === 4 || x.id === 5 || x.id === 6 || x.id === 7 || x.id === 8){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,1,4,5,6,7,8]);
                                          break;
                                        case 'VINHOS_ROSES':
                                          setCategoryUUID('VINHOS_ROSES')
                                          setValue("occasionIds", [1,2,3,4,5,6]);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 1 || x.id === 3 || x.id === 4 || x.id === 5 || x.id === 6 || x.id === 7 || x.id === 8){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,1,3,4,5,6,7,8]);
                                          break;
                                        case 'VINHOS_TINTOS_LEVES':
                                          setCategoryUUID('VINHOS_TINTOS_LEVES')
                                          setValue("occasionIds", [1,2,3,4,5,6,7]);
                                          setValue("harmonizationIds", [2,3,4,5,6,7,8]);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 2 || x.id === 3 || x.id === 4 || x.id === 5 || x.id === 6 || x.id === 7 || x.id === 8){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,2,3,4,5,6,7,8]);
                                          break;
                                        case 'VINHOS_TINTOS_DE_MEDIO_CORPO':
                                          setCategoryUUID('VINHOS_TINTOS_DE_MEDIO_CORPO')
                                          setValue("occasionIds", [6,7]);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 2 || x.id === 3 || x.id === 5 || x.id === 6 || x.id === 7){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,2,3,5,6,7]);
                                          break;
                                        case 'VINHOS_TINTOS_ENCORPADOS':
                                          setCategoryUUID('VINHOS_TINTOS_ENCORPADOS')
                                          setValue("occasionIds", [6]);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 2 || x.id === 3 || x.id === 6 || x.id === 7){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,2,3,6,7]);

                                          break;
                                        case 'VINHOS_DE_SOBREMESA ':
                                          setCategoryUUID('VINHOS_DE_SOBREMESA')
                                          setValue("occasionIds", []);
                                          for(let x of harmonizations){
                                            if (x.harmonizations !== undefined)
                                              for (let item of x.harmonizations){
                                                if (x.id === 6 || x.id === 9){
                                                  Harmonizations.push(item.id)
                                                }
                                              }
                                          }
                                          setValue("harmonizationIds", [...Harmonizations,6,9]);
                                          break;                                                                                                                              

                                      }

                                    } else {                                      
                                      categoryIds.splice(
                                        categoryIds.indexOf(catId),
                                        1
                                      );
                                      setCategoryUUID('')
                                      setValue("occasionIds", []);
                                      setValue("harmonizationIds", []);
                                    }
                                    setValue("categoryIds", categoryIds);
                                  }}
                                />
                              )}
                            />
                          )
                        )}
                    </Styled.CheckboxContent>
                  </Styled.CheckboxBox>
                ))}
              </div>
            </Styled.Content>
          </div>
          <Styled.Actions>
            <FormActions id={id} loading={loading} />
          </Styled.Actions>
        </Styled.Form>
      </Styled.Main>
      {/* <ConfirmDelete open={openConfirm} onClose={() => setOpenConfirm(false)} /> */}
    </Container>
    )
  ;
};

export { ProductAddPageForm };
