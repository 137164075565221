import { IOrderResponse, IOrdersResponse } from "src/shared/types/Order";
import axiosInstance from "src/shared/services/api";

const uri = "order";

export const orders = async (): Promise<IOrdersResponse> => {
  return axiosInstance.get(`/${uri}`);
};

export const invoiceUpload = async (
  id: number,
  file: any
): Promise<IOrderResponse> => {
  return axiosInstance.post(`${uri}/${id}/upload`, file);
};

export const invoiceNumberUpdate = async (
  id: number,
  invoiceNumber: string
): Promise<IOrderResponse> => {
  return axiosInstance.patch(`${uri}/${id}/invoice-update/${invoiceNumber}`);
};

export const deliveryOrder = async (id: number): Promise<IOrderResponse> => {
  return axiosInstance.get(`${uri}/shipment/${id}`);
};


export const tagOrder = async (id: number): Promise<IOrderResponse> => {
  return axiosInstance.get(`${uri}/tag/${id}`);
};
