import React from "react";

import {
  getMe,
  setAuthenticatedUser,
  login,
  logout,
} from "src/shared/services/Auth";
import { IUser } from "src/shared/types/User";
import { IAuthLogin } from "src/shared/types/Auth";
import { AUTH_TOKEN, ROUTE_AFTER_LOGOUT } from "src/shared/constants/auth";

export interface IAuthContext {
  user: IUser;
  reload: () => void;
  isLogged: boolean;
  setIsLogged: (value: boolean) => void;
  sigin: (values: IAuthLogin) => Promise<boolean>;
  loading: boolean;
  setLoading: (value: boolean) => void;
  signout: () => void;
}

interface IProps {
  children: any;
}

const AuthContext = React.createContext({} as IAuthContext);

const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser>({} as IUser);

  const signout = React.useCallback(async () => {
    try {
      await logout();
      localStorage.clear();
      window.location.href = ROUTE_AFTER_LOGOUT;
    } catch (err) {
      console.log(err);
    }
  }, []);

  const sigin = React.useCallback(
    async (values: IAuthLogin): Promise<boolean> => {
      try {
        const { status, data } = await login(values);

        if (data.role !== "CLIENT" && [200, 201].includes(status)) {
          setIsLogged(true);
          localStorage.setItem(AUTH_TOKEN, data.access_token);
          return true;
        }
        return false;
      } catch (err) {
        setIsLogged(false);
        return false;
      }
    },
    []
  );

  const reload = React.useCallback(async (): Promise<boolean> => {
    try {
      const { status, data } = await getMe();

      if (status === 200) {
        setIsLogged(true);
        setAuthenticatedUser(data);
        setUser(data);
      } else {
        setIsLogged(false);
        setAuthenticatedUser({} as IUser);
        setUser({} as IUser);
      }
      return true;
    } catch (e: any) {
      console.log(e.message);
      return false;
    }
  }, []);

  React.useEffect(() => {
    reload();
  }, [reload]);

  const value = React.useMemo(
    () => ({
      user,
      reload,
      isLogged,
      setIsLogged,
      sigin,
      loading,
      setLoading,
      signout,
    }),
    [user, reload, isLogged, setIsLogged, sigin, loading, setLoading, signout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext =>
  React.useContext<IAuthContext>(AuthContext);

export const { Consumer } = AuthContext;

export { AuthProvider };
