import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const GoBack = styled.a`
  display: flex;
  width: 50%;
  text-decoration: none;

  h3 {
    color: ${colors.darkGray};
    font-weight: 700 !important;
    font-size: 20px !important;
    margin-left: 23px;
  }
`;

export const Action = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  p > a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: ${colors.rose};
    font-size: 14px !important;
    font-weight: 400 !important;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    border: 0;
    background: none;
    cusor: pointer;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: ${colors.rose};
    font-size: 14px !important;
    font-weight: 400 !important;
    &:hover {
      text-decoration: underline;
    }
  }
`;
