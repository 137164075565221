import styled from "styled-components";
import colors from "src/shared/theme/colors";

interface IProps {
  open: boolean;
}

export const Full = styled.div`
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  z-index: 15;
  top: 0;
  left: 0;
  display: ${(props: IProps) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 40px;
  background-color: ${colors.white};
  box-shadow: 0px 0px 30px rgba(51, 48, 62, 0.2);
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
export const Title = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  p {
    font-size: 24px;
    font-weight: 700;
    color: ${colors.darkGray};
    text-align: center;
  }
`;
export const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  p {
    font-size: 12px;
    font-weight: 700;
    color: ${colors.lightGray};
    text-align: center;
  }
`;
export const Actions = styled.div`
  margin-top: 20px;
  width: 100%;
  button {
    width: 100%;
    margin-bottom: 10px;
  }
  .secondary {
    background-color: ${colors.white};
    color: ${colors.rose};
    border: 1px solid ${colors.rose} !important;
    &:hover {
      background-color: ${colors.rose};
      color: ${colors.white};
    }
  }
`;
