import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Typography,
  TextField,
  FormControl,
  Button,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ReactComponent as Logo } from "src/assets/svg/logomini.svg";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { recovery } from "src/shared/services/Auth";
import { IAuthForget } from "src/shared/types/Auth";

import * as Styled from "src/shared/theme/login";

const messages = defineMessages({
  forgetPassword: { id: "forget.password" },
  descriptionPassword: { id: "forget.password.description" },
  email: { id: "global.email" },
  recoverPassword: { id: "forget.password.recover" },
  checkoutYourEmail: { id: "forget.check.your.email" },
  descriptionEmail: { id: "forget.email.description" },
  youAreNotProvider: { id: "forget.you.are.not.provider" },
  contactUs: { id: "forget.contact.us" },
  descriptionEmailSpam: { id: "forget.did.not.get.the.email.verify.box.spam" },
  our: { id: "forget.our" },
  tryDifferentEmail: { id: "forget.try.different.email" },
  validEmail: { id: "enter.valid.email.address" },
  requiredField: { id: "required.field" },
});

const ForgetPage = () => {
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const [loading, setLoading] = React.useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(formatMessage(messages.validEmail))
      .required(formatMessage(messages.requiredField)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAuthForget>({
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: IAuthForget) => {
    setLoading(true);

    const { status } = await recovery(values);

    if ([200, 201].includes(status)) {
      openSnackbar({
        message: "Email enviado com sucesso.",
        severity: "success",
      });
      setTimeout(() => (window.location.href = "/nova-senha"), 2000);
    } else {
      setLoading(false);
      openSnackbar({
        message:
          "Não foi possível mandar o e-mail, tente novamente mais tarde.",
        severity: "error",
      });
    }
  };

  return (
    <Styled.Main>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.BoxLogin>
        <Styled.Login onSubmit={handleSubmit(onSubmit)}>
          <Styled.Title>
            <Typography variant="h1">Esqueceu a senha? </Typography>
          </Styled.Title>
          <Styled.Subtitle>
            <Typography>
              Não se preocupe nós te ajudaremos a recuperá-la! Para dar início
              ao processo de recuperação da sua senha digite seu e-mail.
            </Typography>
          </Styled.Subtitle>
          <Styled.Form>
            <FormControl>
              <TextField
                fullWidth
                label="Email"
                size="small"
                helperText={errors.email?.message}
                error={Boolean(errors.email)}
                {...register("email")}
              />
            </FormControl>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                "RECUPERAR SENHA"
              ) : (
                <CircularProgress size={20} color="secondary" />
              )}
            </Button>
          </Styled.Form>
          <Styled.Footer>
            <Typography>
              Não é um fornecedor ainda?{" "}
              <a href="https://besthunterwine.com.br/parceiro">
                Entre em contato
              </a>
            </Typography>
          </Styled.Footer>
        </Styled.Login>
      </Styled.BoxLogin>
    </Styled.Main>
  );
};

export { ForgetPage };
