import React from "react";
import { ICategory } from "@shared/types/Categories";
import { all } from "src/shared/services/Categories";

export function useCategories() {
  const [categories, setCategories] = React.useState<ICategory[]>([]);

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await all();
      if (status === 200) {
        setCategories(data);
      }
      throw new Error();
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return { categories };
}
