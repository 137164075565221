import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Header = styled.div`
  display: flex;
`;
export const HeaderIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: ${colors.darkGray};
  }
`;

export const Products = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 16px;
  background-color: ${colors.white};
  h2 {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkGray};
    border-bottom: 1px solid ${colors.superLightGray};
    padding-bottom: 14px;
  }
`;
export const Product = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  &:first-child {
    padding-top: 0;
  }
`;

export const IN = styled.div`
  display: flex;
  position: relative;
`;

export const Image = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${colors.partnersGray};
  background-color: ${colors.white};
`;
export const CircleNumber = styled.div`
  background-color: ${colors.wineLight};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  left: 50px;
  p {
    font-size: 12px;
    font-weight: 600;
    color: ${colors.white};
  }
`;
export const Name = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${colors.darkGray};
  }
`;
export const Price = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: ${colors.rose};
  }
`;
export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const TotalTitle = styled.div`
  display: flex;
  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: ${colors.darkGray};
  }
`;
export const TotalPrice = styled.div`
  display: flex;
  p {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: ${colors.rose};
  }
`;
export const SubTotal = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
export const SubTotalTitle = styled.div`
  display: flex;
  p {
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 400;
  }
`;
export const SubTotalPrice = styled.div`
  display: flex;
  p {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: ${colors.darkGray};
  }
`;
export const FormOfPayment = styled.div`
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  color: ${colors.darkGray};
  font-size: 14px;
  font-weight: 400;
`;

export const Freight = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  color: ${colors.darkGray};
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid ${colors.superLightGray};
  padding-bottom: 14px;
  h5 {
    width: 50%;
    font-size: 14px;
    font-weight: 400;
  }
  p {
    width: 50%;
    font-size: 14px;
    font-weight: 700;
    text-align: end;
  }
`;

export const Address = styled.div`
  margin-bottom: 35px;
  display: flex;
  color: ${colors.darkGray};
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  h5 {
    width: 50%;
    font-size: 14px;
  }
  p {
    width: 50%;
    font-size: 14px;
    font-weight: 500;
  }
`;
export const Edit = styled.button`
  text-align: start;
  color: ${colors.darkGray};
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 20%;
  p {
    font-size: 14px;
    font-weight: 600;
  }
`;
export const Payment = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  margin-top: 24px;
  border-radius: 10px;
`;
export const HeaderDetailsPayment = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkGray};
    border-bottom: 1px solid ${colors.superLightGray};
    padding-bottom: 14px;
  }
  margin-bottom: 15px;
`;
export const Content = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.lightGray};
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.darkGray};
  }
`;
export const CardAndIcon = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const OrderDelivered = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  margin: 24px 0;
  border-radius: 10px;
`;
export const HeaderOrderDelivered = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkGray};
    border-bottom: 1px solid ${colors.superLightGray};
    padding-bottom: 14px;
  }
  margin-bottom: 15px;
`;
export const CodeAndCopy = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    color: ${colors.blueDark};
    font-weight: 400;
    font-size: 16px;
  }
`;
export const PersonalDataUser = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  width: 100%;
  margin: 24px 0;
  border-radius: 10px;
`;
export const HeaderPersonalDataUser = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkGray};
    border-bottom: 1px solid ${colors.superLightGray};
    padding-bottom: 14px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.lightGray};
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.darkGray};
  }
  margin-bottom: 15px;
`;
export const ContentUser = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.lightGray};
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.darkGray};
  }
  margin-bottom: 16px;
`;

export const ButtonsTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    button {
      margin: 12px 0;
    }
  }
`;
