import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { useIntl, defineMessages } from "react-intl";
import { ConfirmDelete } from "src/components/ConfirmDelete";

interface IProps {
  id: string | undefined;
  loading: boolean;
  onConfirmExclude?: () => void;
}

const messages = defineMessages({
  add: { id: "global.add" },
  edit: { id: "global.edit" },
  exclude: { id: "global.exclude" },
});

const FormActions: React.FC<IProps> = ({ id, loading, onConfirmExclude }) => {
  const { formatMessage } = useIntl();
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  return (
    <>
      {id && (
        <div className="buttonActions">
          <Button
            type="button"
            className="secondary"
            onClick={() => setOpenConfirm(true)}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              formatMessage(messages.exclude).toUpperCase()
            )}
          </Button>
        </div>
      )}
      <div className={`buttonActions ${id ? "right" : ""}`}>
        <Button type="submit" disabled={loading}>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            formatMessage(id ? messages.edit : messages.add).toUpperCase()
          )}
        </Button>
      </div>
      {typeof onConfirmExclude !== "undefined" && (
        <ConfirmDelete
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          onConfirm={() => onConfirmExclude()}
        />
      )}
    </>
  );
};

export { FormActions };
