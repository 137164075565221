import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const GoBack = styled.a`
  display: flex;
  width: 100%;
  text-decoration: none;
  justify-content: space-between;

  h3 {
    color: ${colors.darkGray};
    font-weight: 700 !important;
    font-size: 20px !important;
    margin-left: 23px;
  }

  .price {
    color: ${colors.rose};
    font-weight: 600 !important;
    font-size: 16px !important;
  }
`;

export const GoBackTitle = styled.div`
  display: flex;
  width: 100%;
`;

export const Date = styled.div`
  width: 100%;
  margin: 16px 0;
  p {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: ${colors.lightGray};
  }
`;
