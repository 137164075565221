import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-color: white;
  .MuiTextField-root {
    margin-top: 24px;
    & .Mui-focused {
      color: ${colors.gray} !important;
    }
  }
  .MuiFormLabel-root {
    color: ${colors.gray};
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const ModalItem = styled.div`
  margin-top: 5px;
`;
export const ModalInvoice = styled.div`
  display: flex;
  background-color: black;
  height: 100%;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;
