import * as React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import colors from "src/shared/theme/colors";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { ReactComponent as Invoice } from "src/assets/svg/invoice.svg";

import { FormControl, TextField, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { useForm } from "react-hook-form";

import * as Styled from "./styles";
import { invoiceNumberUpdate, invoiceUpload } from "src/shared/services/Orders";
// import { invoiceUpload } from "@shared/services/Orders";

interface IProps {
  invoiceId: number;
  invoiceNumber: string;
  invoiceLink?: string;
  onClose: () => void;
}

interface IForm {
  invoiceNumber: string;
}

const InvoiceModal: React.FC<IProps> = ({
  invoiceId,
  invoiceNumber,
  invoiceLink,
  onClose,
}) => {
  const { openSnackbar } = useSnackbarConsumer();
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const { handleSubmit, register, setValue } = useForm<IForm>();
  const [loadingFile, setLoadingFile] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const inputFile = React.useRef<any>(null);

  const handleChangeImg = () => {
    inputFile.current.click();
  };

  const handleSubmitFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLoadingFile(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { status } = await invoiceUpload(invoiceId, formData);
      if ([200, 201].includes(status)) {
        setLoadingFile(false);
        openSnackbar({
          message: "PDF armazenado!",
          severity: "success",
        });
      } else {
        openSnackbar({
          message: "Não foi possível armazenar o PDF, tente novamente.",
          severity: "error",
        });
        setLoadingFile(false);
      }
      inputFile.current.value = "";
    }
  };

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    const { status } = await invoiceNumberUpdate(
      invoiceId,
      values.invoiceNumber
    );

    if ([200, 201].includes(status)) {
      openSnackbar({
        message: "Dados atualizados!",
        severity: "success",
      });
    } else {
      openSnackbar({
        message: "Tente novamente mais tarde.",
        severity: "error",
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (invoiceNumber) setValue("invoiceNumber", invoiceNumber);
  }, [invoiceNumber, setValue]);

  return (
    <>
      <Button
        style={{
          backgroundColor: "#fff",
          border: "1px solid #7F0E3D",
          margin: "24px 0",
          marginRight: "5px",
          padding: "12px 12px",
          width: "100%",
        }}
        onClick={() => {
          if (invoiceNumber && invoiceNumber.length > 0) {
            window.open(`https://s3.amazonaws.com/static.besthunterwine.com.br/${invoiceLink}`);
          } else {
            setOpen(true);
          }
        }}
      >
        <Invoice />
        <Typography
          style={{
            color: "#7F0E3D",
            fontSize: "14px",
            fontWeight: 400,
            paddingLeft: "12px",
            width: "100%",
          }}
        >
          {invoiceNumber && invoiceNumber.length > 0
            ? "Nota Fiscal"
            : "Informar nota fiscal"}
        </Typography>
      </Button>
      {invoiceNumber && invoiceNumber.length > 0 &&
      <Button
        style={{
          backgroundColor: "#fff",
          border: "1px solid #7F0E3D",
          margin: "24px 0",
          marginRight: "5px",
          padding: "12px 12px",
          width: "100%",
        }}
        onClick={() => {
            setOpen(true);
        }}
      >
        <Invoice />
        <Typography
          style={{
            color: "#7F0E3D",
            fontSize: "14px",
            fontWeight: 400,
            paddingLeft: "12px",
            width: "100%",
          }}
        >
          {"Informar Nova Nota fiscal"}
        </Typography>
      </Button>      
      }
      {open === true && (
        <Styled.ModalInvoice>
          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            sx={{ backgroundColor: colors.darkGray }}
          >
            <Container maxWidth="sm">
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 340,
                  padding: "40px",
                }}
              >
                <Styled.ModalItem>
                  <Styled.Form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="h2">Nota fiscal</Typography>
                    <FormControl>
                      <TextField
                        fullWidth
                        label="Número da nota"
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...register("invoiceNumber")}
                      />
                    </FormControl>

                    <Button
                      style={{
                        backgroundColor: "#7F0E3D",
                        margin: "24px 0",
                        marginRight: "5px",
                        padding: "12px 12px",
                        width: "100%",
                      }}
                      disabled={loading}
                      type="submit"
                    >
                      <Typography
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingLeft: "12px",
                          width: "100%",
                        }}
                      >
                        {loadingFile ? "Aguarde..." : "Gravar número"}
                      </Typography>
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#FFF",
                        marginRight: "5px",
                        padding: "12px 12px",
                        width: "100%",
                        border: "1px solid #7F0E3D",
                      }}
                      onClick={handleChangeImg}
                      disabled={loadingFile}
                      type="button"
                    >
                      <input
                        type="file"
                        ref={inputFile}
                        onChange={handleSubmitFile}
                        style={{ display: "none" }}
                        accept="application/pdf"
                      />
                      <Typography
                        style={{
                          color: "#7F0E3D",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingLeft: "12px",
                          width: "100%",
                        }}
                      >
                        {loadingFile ? "Aguarde..." : "Enviar PDF"}
                      </Typography>
                    </Button>
                  </Styled.Form>
                </Styled.ModalItem>
              </div>
            </Container>
          </Modal>
        </Styled.ModalInvoice>
      )}
    </>
  );
};

export { InvoiceModal };
