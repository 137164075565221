import styled from "styled-components";

export const Payment = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
  div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    font-size: 25px;
  }
`;
