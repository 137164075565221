import React from "react";
import { Container, Typography } from "@mui/material";
import { StatusType } from "src/components/StatusType";
import { HeaderInternal } from "src/components/HeaderInternal";

import * as Styled from "./styles";
import { orders } from "src/shared/services/Users";
import { AlertMessage } from "src/components/AlertMessage";
import { IOrder, IOrderStatusList } from "src/shared/types/Order";
import { defineMessages, useIntl } from "react-intl";

import { format } from "date-fns";
import { useAuth } from "src/shared/providers/auth";

const messages = defineMessages({
  pageRequest: { id: "orders.page.request" },
  undefined: { id: "global.undefined" },
  CREATED: { id: "orders.status.created" },
  PAYMENT_ACCEPTED: { id: "orders.status.payment.accepted" },
  PAYMENT_DECLINED: { id: "orders.status.payment.declined" },
  IN_SEPARATION: { id: "orders.status.in.separation" },
  IN_ROUTE: { id: "orders.status.in.route" },
  DELIVERED: { id: "orders.status.delivered" },
  CANCELED: { id: "orders.status.canceled" },
  AWAITING_PAYMENT: { id: "orders.status.awaiting.payment" },
});


const OrdersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState<IOrder[]>([]);
  const [messageOpen, setMessageOpen] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { user } = useAuth();
  const handleData = React.useCallback(async () => {
    const { status, data: ord } = await orders();
    if ([200, 201].includes(status)) {
      await setData(ord);
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    const arrayParternUnique: any = []
      for(let item of data){
        if (arrayParternUnique.find((i:any) => i.id === item.partner.id) === undefined)
          arrayParternUnique.push({name:item.partner.name, id:item.partner.id})
      }
      const sorted = arrayParternUnique.sort((a:any, b:any) => a.name.localeCompare(b.name))
      setSelected(sorted)
      const arrayStatusUnique: any = []
  },[data])

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  const handleStatus = (status: IOrderStatusList) => {
    const warning = [
      "CREATED",
      "AWAITING_PAYMENT",
      "IN_ROUTE",
      "IN_SEPARATION",
    ];
    const error = ["PAYMENT_DECLINED", "CANCELED"];
    const success = ["PAYMENT_ACCEPTED", "DELIVERED"];

    if (warning.includes(status)) {
      return "warning";
    } else if (error.includes(status)) {
      return "error";
    } else if (success.includes(status)) {
      return "success";
    } else {
      return "warning";
    }
  };

  const [selectPartner, setSelectPartner] = React.useState<string>("");
  const [selected, setSelected] = React.useState<any[]>([]);
  const [status,setStatus] = React.useState<string>("");
  const arrayStatus = []

  return (
    <Container maxWidth="sm">
      <Styled.Main>
        <HeaderInternal label="Pedidos" link="" />
        {data.length > 0 && user && user.role === "ADMIN" && (
          <div style={{
            display: "flex",
            fontSize: "10px",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flexDirection: "column",
          }}>
            <Typography style={
              {flex: 0, justifyContent: "center" , fontSize: "10px", display: "flex", alignItems: "center", gap: 10, marginBottom: "10px"}
            }>
              <p>Filtrar Parceiro</p>
              <select onChange={(e: any) => setSelectPartner(e.target.value)} value={selectPartner} title="partners">
                <option value="">Todos Parceiros</option>
                {selected.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  )
                )}
              </select>
            </Typography>
            <Typography style={
              {flex: 0, justifyContent: "center" , fontSize: "10px", display: "flex", alignItems: "center", gap: 10}
            }>
              <p>Filtrar Status</p>
              <select onChange={(e: any) => setStatus(e.target.value)} value={status} title="status">
                <option value="">Todos Status</option>
                <option value={"CREATED"}>{formatMessage(messages.CREATED)}</option>
                <option value={"PAYMENT_ACCEPTED"}>{formatMessage(messages.PAYMENT_ACCEPTED)}</option>
                <option value={"PAYMENT_DECLINED"}>{formatMessage(messages.PAYMENT_DECLINED)}</option>
                <option value={"IN_SEPARATION"}>{formatMessage(messages.IN_SEPARATION)}</option>
                <option value={"IN_ROUTE"}>{formatMessage(messages.IN_ROUTE)}</option>
                <option value={"DELIVERED"}>{formatMessage(messages.DELIVERED)}</option>
                <option value={"CANCELED"}>{formatMessage(messages.CANCELED)}</option>
                <option value={"AWAITING_PAYMENT"}>{formatMessage(messages.AWAITING_PAYMENT)}</option>
              </select>
            </Typography>
          </div>
        )}
        <Styled.Orders>
          {data.length > 0 &&
            data.map((item: IOrder, index: number) => (
              <Styled.Order 
              style={{
                display: ((status !== "" && item.statuses && item.statuses[0] && item.statuses[0].status !== status) || (selectPartner !== "" && item.partner?.id?.toString() !== selectPartner)) ? "none" : "block",
              }}
              key={`ord${index}`}>
                <a href={`/pedidos/${item.id}`} style={{width:'100%'}}>
                  <Styled.Resume>
                    <Styled.OrderStatus>
                      <Typography>Pedido #{item.id}</Typography>
                      {/* <Styled.StatusPayment>
                        <Typography>Aguardando pagamento</Typography>
                      </Styled.StatusPayment> */}
                      <Styled.Light>
                        <StatusType
                          label={
                            item.statuses &&
                            item.statuses[0] &&
                            item.statuses[0].status
                              ? formatMessage(messages[item.statuses[0].status])
                              : formatMessage(messages.undefined)
                          }
                          color={handleStatus(
                            item.statuses && item.statuses[0]
                              ? item.statuses[0].status
                              : ""
                          )}
                        />
                      </Styled.Light>
                    </Styled.OrderStatus>
                    <Styled.Date>
                      <Typography>
                        {item &&
                          item.createdAt &&
                          format(new Date(item.createdAt), "dd/MM/yyyy")}
                      </Typography>
                    </Styled.Date>
                  </Styled.Resume>
                  <div className="small">
                    <Typography>
                      {user && user.role === "ADMIN" && (
                        <small>{item.partner?.name}</small>
                      )}
                    </Typography>
                  </div>
                </a>
              </Styled.Order>
            ))}
        </Styled.Orders>
        {!loading && data.length === 0 && (
          <AlertMessage
            onClose={() => setMessageOpen(false)}
            open={messageOpen}
            title="Você ainda não possui nenhum pedido!"
            subTitle=" "
          />
        )}
      </Styled.Main>
    </Container>
  );
};

export { OrdersPage };
