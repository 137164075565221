import React from "react";
import { Typography } from "@mui/material";
import SecLogo from "src/assets/images/SacurityFooter.jpg";

import * as Styled from "./styles";

const SecuritySiteFooter: React.FC = () => {
  return (
    <Styled.Security>
      <div>
        <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
          Site Seguro
        </Typography>
        <img src={SecLogo} width={130} height={30} alt="Safe Browsing" />
      </div>
      <Typography>
        ©{new Date().getFullYear()} Todos os direitos reservados
      </Typography>
    </Styled.Security>
  );
};

export { SecuritySiteFooter };
