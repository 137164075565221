import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "src/assets/svg/Edit.svg";
import * as Styled from "./styles";

interface IProps {
  image?: string;
  title: string;
  subtitle?: string;
  extra?: string;
  imageCentered?: boolean;
  url?: string;
  safra?: string;
  order?: string;
}

const ListItem: React.FC<IProps> = ({
  image,
  title,
  subtitle,
  extra,
  imageCentered,
  url,
  safra,
  order
}) => {
  return (
    <Styled.Item style={{cursor:"pointer"}} onClick={(e) => {
      window.location.href = url || "#";
    }} >
      {image && 
        <Styled.Image center={imageCentered}>
          {image && <img src={image} alt="" />}
        </Styled.Image>
      }
      <Styled.Information>
        <Styled.Title>
          <Typography>{title}</Typography>
        </Styled.Title>
        <Styled.Subtitle>
          <Typography>{subtitle || ""}</Typography>
        </Styled.Subtitle>
        <Styled.Extra>
          <Typography>{extra || ""}</Typography>
        </Styled.Extra>
        {safra && (
        <Styled.TextSafra>
          <Typography style={{
            fontSize: "11px",
            fontWeight: "bold",
          }}>Safra: {safra || ""}</Typography>
        </Styled.TextSafra>
        )}
        {order && (
        <Styled.TextSafra>
          <Typography style={{
            fontSize: "11px",
            fontWeight: "bold",
          }}>Ordem: {order || ""}</Typography>
        </Styled.TextSafra>
        )}
        {url &&
        <Styled.Action href={url || "#"}>
          <EditIcon />
        </Styled.Action>
        }
      </Styled.Information>
    </Styled.Item>
  );
};

export { ListItem };
