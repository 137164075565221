import React from "react";
import { Container, CircularProgress } from "@mui/material";
import { HeaderInternal } from "src/components/HeaderInternal";
import { ListItem } from "src/components/ListItem";
import { Paginate } from "src/components/Paginate";
import withAuthenticationRequired from "src/shared/hocs/with-authentication-required";
import { list, signatures } from "src/shared/services/Users";
import { IUser } from "src/shared/types/User";
import { IMeta } from "src/shared/types/Pagination";
import { NoResultsList } from "src/components/NoResultsList";

import * as Styled from "src/shared/theme/form-styles";

const uri = "assinaturas";

const SignaturePage: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>([]);


  const handleData = React.useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await signatures();
      if (status === 200) {
        setData(data)
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal label="Assinantes" add={false} uri={uri} />
        {data && data.length >= 1 ? (
          <>
            {!loading ? (
              <>
                <table style={{
                  marginTop: "20px",
                  width: "100%",
                  borderCollapse: "collapse",
                  marginBottom: "20px"
                }}>
                    <tr style={{
                      backgroundColor: "#EEE",
                      fontFamily: "Roboto, sans-serif",
                    }}>
                      <th style={{
                        width: "20%",
                        padding: "10px"
                      }}>Nome</th>
                      <th style={{
                        width: "20%",
                        padding: "10px"
                      }}>CPF</th>
                      <th style={{
                        width: "20%"
                      }}>Contato</th>
                      <th style={{
                        width: "20%"
                      }}>E-mail</th>
                      <th style={{
                        width: "20%"
                      }}>Plano</th>
                      <th style={{
                        width: "20%"
                      }}>Endereço</th>
                    </tr>
                  {data.map((item: any, index: number) => (                    
                    <tr style={{
                      fontFamily: "Roboto, sans-serif",
                      backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#fff"
                    }} key={item.id}>
                      <td style={{
                        padding: "10px"
                      }}>{item.name} {item.surname}</td>
                      <td style={{
                        padding: "10px"
                      }}>{item.document}</td>
                      <td style={{
                        padding: "10px"
                      }}>{item.cellphone}</td>
                      <td style={{
                        padding: "10px"
                      }}>{item.email}</td>
                      <td style={{
                        padding: "10px"
                      }}>{item.typeOfPlan}</td>
                      <td style={{
                        padding: "10px"
                      }}>{item.addressId.sort((a: any, b:any) => b.createdAt ).slice(-1).map((item: any) => `${item.street},${item.number}, ,${item.complement}, ,${item.zipCode} | ${item.city}/${item.state}`)}</td>
                    </tr>
                  ))}
                </table>
              </>
            ) : (
              <Styled.Loading>
                <CircularProgress size={30} />
              </Styled.Loading>
            )}
          </>
        ) : (
          <Styled.NoResults>
            <NoResultsList uri={uri} />
          </Styled.NoResults>
        )}
      </Styled.Main>
    </Container>
  );
};

export default withAuthenticationRequired(SignaturePage);
