import axios from "axios";
import { AUTH_TOKEN, AUTH_USER } from "src/shared/constants/auth";
import axiosInstance from "src/shared/services/api";
import { IAuth } from "src/shared/types/Auth";
import {
  IAuthResponse,
  IAuthLogin,
  IAuthForget,
  IAuthForgetResponse,
  IAuthNewPassword,
} from "src/shared/types/Auth";
import { IUser, IUsersResponse } from "src/shared/types/User";

export const login = (data: IAuthLogin): Promise<IAuthResponse> => {
  return axiosInstance.post("/login", data);
};

export const logout = (): Promise<void> => {
  return axiosInstance.post("/logout", {});
};

export const getMe = async (): Promise<IUsersResponse> => {
  return axios.get("https://api.besthunterwine.com.br/me",{
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const recovery = async (
  data: IAuthForget
): Promise<IAuthForgetResponse> => {
  return axiosInstance.post("/forgot-password/", data);
};

export const getToken = (): string | null => localStorage.getItem(AUTH_TOKEN);

export const getAuthenticatedUser = (): IUser | string | null => {
  let user: IUser | string | null = localStorage.getItem(AUTH_USER);

  if (user) {
    user = JSON.parse(user);
    return user;
  }

  return null;
};

export const clearAuthenticatedUser = async (
  logoutApi = false
): Promise<void> => {
  if (logoutApi) await logout();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_USER);
};

export const setAuthenticatedToken = (auth: IAuth): void => {
  localStorage.setItem(AUTH_TOKEN, auth.access_token);
};

export const setAuthenticatedUser = (user: IUser): void => {
  localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const updatePassword = async (
  data: IAuthNewPassword
): Promise<IAuthForgetResponse> => {
  return axiosInstance.patch(`/update-forgot-password`, data);
};
