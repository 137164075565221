import styled from "styled-components";

export const StyledModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      margin-bottom: 20px;

      p {
        font-size: 26px;
        font-weight: 800;
      }
    }
    .subtitle {
      p {
        font-size: 18px;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      .MuiFormControl-root {
        margin: 5px 0;
      }
    }
  }
`;
