import React from "react";
import { Container, CircularProgress } from "@mui/material";
import { HeaderInternal } from "src/components/HeaderInternal";
import { ListItem } from "src/components/ListItem";
import { Paginate } from "src/components/Paginate";
import { all } from "src/shared/services/Categories";
import { IMeta } from "src/shared/types/Pagination";
import { IProduct } from "@shared/types/Products";
import { NoResultsList } from "src/components/NoResultsList";
import { useLocation } from "react-router-dom";

import * as Styled from "src/shared/theme/form-styles";

const uri = "paises";

const PaisesPage: React.FC = () => {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const handleData = React.useCallback(async () => {
    try {
      setLoading(true);
      const { status, data } = await all();
      if (status === 200) {
        const paises: any = data.filter((item: any) => item.UID === "PAISES");
        if (paises.length > 0) {
          const cat = paises[0].categories.sort((a: any, b: any) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
          })
          console.log(paises)
          setData(cat);
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal label="Paises" uri={uri} add />

        {data && data.length >= 1 ? (
          <>
            {!loading ? (
              <>
                <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "10px",

                }} 
                >
                  {data.map((item, index: number) => (
                    <ListItem
                      key={`idx${index}`}
                      title={item.name}
                      order={item.order}
                      url={`/paises/form/${item.id}`}
                      subtitle={""}
                    />
                  ))}
                </div>
              </>
            ) : (
              <Styled.Loading>
                <CircularProgress size={30} />
              </Styled.Loading>
            )}
          </>
        ) : (
          <Styled.NoResults>
            <NoResultsList uri={uri} />
          </Styled.NoResults>
        )}
      </Styled.Main>
    </Container>
  );
};

export { PaisesPage };
