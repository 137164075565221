import React from "react";
import { Typography, Button } from "@mui/material";
import { useIntl, defineMessages } from "react-intl";

import { ReactComponent as BigWarning } from "src/assets/svg/BigWarning.svg";
import * as Styled from "./styles";

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const messages = defineMessages({
  add: { id: "global.add" },
  exclude: { id: "global.exclude" },
  title: { id: "global.confirm.exclude.title" },
  subtitle: { id: "global.confirm.exclude.subtitle" },
  goBack: { id: "global.goBack" },
});

const ConfirmDelete: React.FC<IProps> = ({ open, onClose, onConfirm }) => {
  const { formatMessage } = useIntl();
  const wrapperRef: any = React.useRef(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current === event.target) {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  });

  return (
    <Styled.Full open={open} ref={wrapperRef}>
      <Styled.Box>
        <Styled.Icon>
          <BigWarning />
        </Styled.Icon>
        <Styled.Title>
          <Typography>{formatMessage(messages.title)}</Typography>
        </Styled.Title>
        <Styled.Subtitle>
          <Typography>{formatMessage(messages.subtitle)}</Typography>
        </Styled.Subtitle>
        <Styled.Actions>
          <Button type="button" onClick={() => onClose()} className="secondary">
            {formatMessage(messages.goBack)}
          </Button>
          <Button type="button" onClick={() => onConfirm()}>
            {formatMessage(messages.exclude)}
          </Button>
        </Styled.Actions>
      </Styled.Box>
    </Styled.Full>
  );
};

export { ConfirmDelete };
