import styled from "styled-components";

export const Social = styled.div`
  width: 70%;
  display: flex;
  margin-bottom: 20px;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    a {
      color: inherit;
      text-decoration: none;
      font-size: 25px;
    }
  }
`;
