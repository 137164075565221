import styled from "styled-components";

export const Security = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 0px;
  }

  div {
    margin-bottom: 20px;
  }
`;
