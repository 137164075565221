import React from "react";
import { Container, CircularProgress } from "@mui/material";
import { HeaderInternal } from "src/components/HeaderInternal";
import { ListItem } from "src/components/ListItem";
import { Paginate } from "src/components/Paginate";
import { list } from "src/shared/services/Products";
import { IMeta } from "src/shared/types/Pagination";
import { IProduct } from "@shared/types/Products";
import { NoResultsList } from "src/components/NoResultsList";
import { useLocation } from "react-router-dom";

import * as Styled from "src/shared/theme/form-styles";

const uri = "produtos";

const ProductsPage: React.FC = () => {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");

  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<IProduct[]>([]);
  const [meta, setMeta] = React.useState<IMeta>({ page: 1, totalPages: 1 });

  const handleData = React.useCallback(async () => {
    try {
      setLoading(true);

      const { status, data: products } = await list(Number(page || 1));
      if (status === 200) {
        setData(products.data);
        setMeta(products.meta);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal label="Produtos" uri={uri} add />

        {data && data.length >= 1 ? (
          <>
            {!loading ? (
              <>
                <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "10px",

                }} 
                >
                  {data.map((item, index: number) => (
                    <ListItem
                      key={`idx${index}`}
                      image={item.image}
                      title={item.name}
                      safra={item.harvest.toString()}
                      url={`/produtos/form/${item.id}`}
                      subtitle={
                        item !== undefined && item.description !== null && item.description.length > 47
                          ? `${item.description.substring(0, 47)}...`
                          : ""
                      }
                      imageCentered
                    />
                  ))}
                </div>
                <Paginate
                  actualPage={meta.page}
                  totalPages={meta.totalPages}
                  uri={uri}
                />
              </>
            ) : (
              <Styled.Loading>
                <CircularProgress size={30} />
              </Styled.Loading>
            )}
          </>
        ) : (
          <Styled.NoResults>
            <NoResultsList uri={uri} />
          </Styled.NoResults>
        )}
      </Styled.Main>
    </Container>
  );
};

export { ProductsPage };
