import React from "react";
import { Typography, TextField, FormControl, Button } from "@mui/material";
import { ReactComponent as Logo } from "src/assets/svg/logomini.svg";

import * as Styled from "src/shared/theme/login";

interface ICompany {
  name: string;
  email: string;
  companyName: string;
  cellphone: string;
}

const BePartnerPage = () => {
  const [formData, setFormData] = React.useState<ICompany>({} as ICompany);

  const handleSubmit = async () => {
    window.location.href = "/";
  };

  return (
    <Styled.Main>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.BoxLogin>
        <Styled.Login onSubmit={handleSubmit}>
          <Styled.Title>
            <Typography variant="h1">Torne-se um parceiro!</Typography>
          </Styled.Title>
          <Styled.Subtitle>
            <Typography>
              Fornecedores de todo território nacional estão por aqui.
              Oferecemos as ferramentas e habilidades para seu marketplace.
              <br />
              <br />
              Preencha os campos abaixo para iniciar nosso pré-cadastro:
            </Typography>
          </Styled.Subtitle>
          <Styled.Form>
            <FormControl>
              <TextField
                fullWidth
                label="Seu nome"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                value={formData.name}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                label="Seu e-mail"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                value={formData.email}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                label="Nome da Empresa"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setFormData({ ...formData, companyName: e.target.value })
                }
                value={formData.companyName}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                label="Telefone (Whatsapp)"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setFormData({ ...formData, cellphone: e.target.value })
                }
                value={formData.cellphone}
              />
            </FormControl>
            <Button type="submit">Quero me cadastrar</Button>
          </Styled.Form>
          <Styled.Footer>
            <Typography>
              Já tem um cadastro? <a href="/">Clique para entrar</a>
            </Typography>
          </Styled.Footer>
        </Styled.Login>
      </Styled.BoxLogin>
    </Styled.Main>
  );
};

export { BePartnerPage };
