export const formatMoney = (
  price: number,
  locale?: string,
  currency?: string
) => {
  return price.toLocaleString(locale || "pt-br", {
    style: "currency",
    currency: currency || "BRL",
  });
};
