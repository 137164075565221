import React from "react";
import { Typography } from "@mui/material";

import { AiOutlineInstagram } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";

import * as Styled from "./styles";

const SocialFooter: React.FC = () => {
  return (
    <Styled.Social>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
        Redes Sociais
      </Typography>
      <div>
        <a href="/">
          <AiOutlineInstagram />
        </a>
        <a href="/">
          <BsFacebook />
        </a>
      </div>
    </Styled.Social>
  );
};

export { SocialFooter };
