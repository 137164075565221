import {
  ICategoryResponse,
  ICategoriesResponse,
} from "src/shared/types/Categories";
import axiosInstance from "src/shared/services/api";

const uri = "category";

export const all = async (): Promise<ICategoriesResponse> => {
  return axiosInstance.get(`/${uri}`);
};

export const listByUID = async (uid: string): Promise<ICategoryResponse> => {
  return axiosInstance.get(`/${uri}/by-uid/${uid}`);
};


export const getById = async(id: number): Promise<ICategoryResponse> => {
  return axiosInstance.get(`/${uri}/${id}`);
}
export const create = async(data: ICategoryResponse): Promise<ICategoryResponse> => {
  return axiosInstance.post(`/${uri}`, data);
};
export const update = async(data: ICategoryResponse, id: number): Promise<ICategoryResponse> => {
  return axiosInstance.put(`/${uri}/${id}`, data);
}
export const remove = async(id: number): Promise<ICategoryResponse> => {
  return axiosInstance.delete(`/${uri}/${id}`);
}
