const colors = {
  white: "#FFF",
  black: "#000",
  wineLight: "#7F0E3D",
  wine: "#5F0329",
  darkGray: "#33303E",
  lightGray: "#8C8A97",
  superLightGray: "#efeeee",
  gray: "#4E4B59",
  blueLight: "#DDEAF3",
  blueDark: "#5a67DF",
  partnersGray: "#F2F7F9",
  rose: "#C4165E",
  grayLight: "#F7FAFB",
};

export default colors;
