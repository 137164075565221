import React from "react";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Button,
  CircularProgress,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IAuthNewPassword } from "src/shared/types/Auth";
import PasswordStrengthBar from "react-password-strength-bar";
import AuthCode from "react-auth-code-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { ReactComponent as Logo } from "src/assets/svg/logomini.svg";
import { updatePassword } from "src/shared/services/Auth";

import * as Styled from "src/shared/theme/login";

const messages = defineMessages({
  createNewPassword: { id: "global.create.new.password" },
  description: { id: "new.password.description" },
  password: { id: "global.password" },
  passwordConfirm: { id: "new.password.confirm" },
  passwordRecover: { id: "forget.password.recover" },
  requiredField: { id: "required.field" },
  requiredPassword: { id: "required.password" },
  passwordDivergents: { id: "new.password.divergents" },
  newPassword: { id: "new.password" },
  newRequiredPassword: { id: "new.password.required" },
  passwordVeryWeak: { id: "password.very.weak" },
  passwordWeak: { id: "password.weak" },
  passwordMedian: { id: "password.median" },
  passwordStrong: { id: "password.strong" },
  passwordVeryStrong: { id: "password.very.strong" },
  code: { id: "forget.code" },
  passwordChanged: { id: "password.changed.title" },
  descriptionPasswordChanged: { id: "password.changed.subtitle" },
  passwordChangeFailed: { id: "password.changed.failed" },
  passwordChangeSuccess: { id: "password.changed.success" },
  codePasswordDisclaimer: { id: "new.password.code.disclaimer" },
});

const NewPasswordPage = () => {
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const schema = yup.object().shape({
    code: yup
      .string()
      .min(6, formatMessage(messages.requiredField))
      .required(formatMessage(messages.requiredField)),
    password: yup
      .string()
      .required(formatMessage(messages.requiredField))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        formatMessage(messages.requiredPassword)
      ),
    confirmationPassword: yup
      .string()
      .required(formatMessage(messages.requiredField))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        formatMessage(messages.passwordDivergents)
      ),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm<IAuthNewPassword>({
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: IAuthNewPassword) => {
    setLoading(true);
    const { status } = await updatePassword(values);

    if ([200, 201].includes(status)) {
      openSnackbar({
        message: formatMessage(messages.passwordChangeSuccess),
        severity: "success",
      });
      setTimeout(() => (window.location.href = "/"), 2000);
    } else {
      openSnackbar({
        message: formatMessage(messages.passwordChangeFailed),
        severity: "error",
      });
      setLoading(false);
    }
  };

  return (
    <Styled.Main>
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.BoxLogin>
        <Styled.Login onSubmit={handleSubmit(onSubmit)}>
          <Styled.Title>
            <Typography variant="h1">Criar nova senha</Typography>
          </Styled.Title>
          <Styled.Subtitle>
            <Typography>
              Sua nova senha precisa ser diferente da senha anterior.
            </Typography>
          </Styled.Subtitle>
          <Styled.Form>
            <FormControl>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <AuthCode
                  allowedCharacters="alphanumeric"
                  inputClassName="inputAuthCode"
                  containerClassName="containerAuthCode"
                  isPassword={false}
                  autoFocus={false}
                  onChange={(value: string) => setValue("code", value)}
                />
              </div>
            </FormControl>
            <FormControl>
              <Typography style={{ textAlign: "center" }}>
                {formatMessage(messages.description)}
              </Typography>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextField
                      className={`inputField ${
                        errors.password ? "error-field" : ""
                      }`}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      label={formatMessage(messages.password)}
                      size="small"
                      autoComplete="off"
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                    <PasswordStrengthBar
                      password={field.value}
                      minLength={8}
                      scoreWordStyle={{
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                      }}
                      shortScoreWord={formatMessage(messages.passwordVeryWeak)}
                      scoreWords={[
                        formatMessage(messages.passwordVeryWeak),
                        formatMessage(messages.passwordWeak),
                        formatMessage(messages.passwordMedian),
                        formatMessage(messages.passwordStrong),
                        formatMessage(messages.passwordVeryStrong),
                      ]}
                    />
                  </>
                )}
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                label={formatMessage(messages.passwordConfirm)}
                size="small"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                helperText={errors.confirmationPassword?.message}
                error={Boolean(errors.confirmationPassword)}
                {...register("confirmationPassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                "RESETAR SENHA"
              ) : (
                <CircularProgress size={20} color="secondary" />
              )}
            </Button>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>
                <a href="/esqueci">Voltar</a>
              </Typography>
            </div>
          </Styled.Form>
          <Styled.Footer>
            <Typography>
              Não é um fornecedor ainda? <a href="/">Entre em contato</a>
            </Typography>
          </Styled.Footer>
        </Styled.Login>
      </Styled.BoxLogin>
    </Styled.Main>
  );
};

export { NewPasswordPage };
