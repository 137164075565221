import React from "react";
import {
  Container,
  FormControl,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormActions } from "src/components/FormActions";
import { getById, create, update } from "src/shared/services/Categories";
import { useSnackbarConsumer } from "src/shared/providers/Snackbar";
import { HeaderInternal } from "src/components/HeaderInternal";
import { ReactComponent as ErrorFieldIcon } from "src/assets/svg/ErrorField.svg";
import * as Styled from "src/shared/theme/form-styles";
import slugify from '@sindresorhus/slugify';

const messages = defineMessages({
  required: { id: "global.required" },
  add: { id: "global.add" },
  edit: { id: "global.edit" },
  productName: { id: "product.name" },
  success: { id: "global.save.success" },
  error: { id: "global.save.error" },
});

const uri = "uvas";

const UvasAddPageForm: React.FC = () => {
  const { formatMessage } = useIntl();

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(3, formatMessage(messages.required))
      .required(formatMessage(messages.required)),
    backgroundColor: yup.string().required(formatMessage(messages.required)),
    order: yup.number().required(formatMessage(messages.required)),
  });

  const { id } = useParams();
  const { openSnackbar } = useSnackbarConsumer();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
  } = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);

    if (id) {
      delete values.textColor;
      delete values.backgroundColor;
      delete values.createdAt;
      delete values.updatedAt;
      delete values.highlight;

      const { status } = await update(values, Number(id));
      if (status === 200) {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        setLoading(false);
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    } else {
      const { status } = await create({...values, categoryId:3, UID: `UVA_${slugify(values.name)}`});

      if (status === 201) {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        setTimeout(() => (window.location.href = `/${uri}`), 500);
      } else {
        setLoading(false);
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        });
      }
    }
  };

  const handleById = React.useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const { status, data } = await getById(id);        
        if (status === 200) {
          if (!data) window.location.href = `/${uri}`;
          reset(data);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [reset]
  );


  React.useEffect(() => {
    if (id) {
      setLoading(true);
      setTimeout(() => {
        handleById(Number(id));
      }, 1500);
    }
  }, [handleById, id]);

  return loading ? (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        <Typography variant="h4">Carregando...</Typography>
      </div>
  ) : (
    <Container maxWidth="xl">
      <Styled.Main>
        <HeaderInternal
          label={`${id ? "Editar" : "Adicionar"} Uva`}
          link={`/${uri}`}
        />
        {/* <GalleryForm onChange={(values: any) => console.log(values)} /> */}
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Title>
            <Typography>Tipo de uva</Typography>
          </Styled.Title>
          <Styled.Content style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: '1rem'
          }}>
            <FormControl className="formControl">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.name ? "error-field" : ""
                    }`}
                    fullWidth
                    label={formatMessage(messages.productName)}
                    size="small"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: errors.name && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="backgroundColor"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    className={`inputField ${
                      errors.image ? "error-field" : ""
                    }`}
                    fullWidth
                    label="Background Color"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: errors.image && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControl">
              <Controller
                name="order"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    type="number"
                    className={`inputField ${errors.name ? "error-field" : ""}`}
                    fullWidth
                    label="Order"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                      endAdornment: errors.harvest && <ErrorFieldIcon />,
                    }}
                    {...field}
                  />
                )}
              />
            </FormControl>
           
          </Styled.Content>
          
         
          <Styled.Actions>
            <FormActions id={id} loading={loading} />
          </Styled.Actions>
        </Styled.Form>
      </Styled.Main>
      {/* <ConfirmDelete open={openConfirm} onClose={() => setOpenConfirm(false)} /> */}
    </Container>
    )
  ;
};

export { UvasAddPageForm };
