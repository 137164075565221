import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Resume = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 10px;
`;

export const Orders = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

export const Order = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 10px;
  margin: 10px 0;
  border-radius: 10px;
  padding: 16px;
  a {
    display: inline-block;
    text-decoration: none;
  }
  &:last-child {
    margin-bottom: 60px;
  }
`;

export const OrderStatus = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    color: ${colors.lightGray}!important;
    font-size: 12px !important;
  }
`;
export const Light = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const Date = styled.div`
  display: flex;
  margin-top: 5px;
  p {
    color: ${colors.black};
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`;
export const StatusPayment = styled.div`
  p {
    color: #7a7786;
    font-size: 12px;
    font-weight: 500;
  }
`;
