import styled from "styled-components";
import colors from "src/shared/theme/colors";

interface IProps {
  center?: boolean;
}

export const Item = styled.div`
  display: flex;
  padding: 8px;
  background-color: ${colors.white};
  border-radius: 10px;
  margin-top: 16px;
  position: relative;
  &:last-child {
    margin-top: 0;
  }
`;

export const TextSafra = styled.div`
  display: flex;
  flex-direction: column;
`

export const Image = styled.div`
  display: flex;
  width: 94px;
  align-items: ${(props: IProps) => (props.center ? "center" : "flex-start")};
  justify-content: ${(props: IProps) =>
    props.center ? "center" : "flex-start"};

  img {
    max-width: 30px;
    border-radius: 10px;
    border: 1px solid ${colors.partnersGray};
  }
`;
export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
export const Title = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGray};
`;
export const Subtitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.lightGray};
  margin-top: 4px;
`;
export const Extra = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.lightGray};
  margin-top: 4px;
`;
export const Action = styled.a`
  position: absolute;
  top: 10px;
  right: 11px;
`;
