import styled from "styled-components";
import colors from "src/shared/theme/colors";

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;
export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 10px;
  background-color: ${colors.white};
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
  &:hover {
    border-color: ${colors.rose};
  }
  img {
    max-width: 50px;
  }
  .remove {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    right: -15px;
    z-index: 10;
  }
`;
