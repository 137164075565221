import { createTheme } from "@mui/material";
import colors from "../shared/theme/colors";

export const theme = createTheme({
  typography: {
    fontFamily: "Montserrat !important",
    button: {
      textTransform: "none",
    },
    body1: {
      fontFamily: "Montserrat !important",
    },
    body2: {
      fontFamily: "Montserrat !important",
    },
    h1: {
      fontFamily: "Montserrat !important",
      fontSize: "32px",
      fontWeight: "700",
    },
  },
  palette: {
    primary: {
      light: colors.rose,
      main: colors.rose,
      dark: colors.wine,
      contrastText: "#fff",
    },
    secondary: {
      main: "#5A67DF",
      dark: "#D9CCA1",
      light: "#16C47B",
      contrastText: "#D98411",
    },
    warning: {
      main: "#DDEAF3",
    },
    info: {
      light: "#C4165E",
      main: "#8C8A97",
      dark: "#7F0E3D",
      contrastText: "#fff",
    },
    success: {
      main: "#16C47B",
    },
    error: {
      main: "#ed6c02",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.rose,
          color: colors.white,
          borderRadius: 10,
          padding: "11px 28px",
          fontWeight: 600,
          fontSize: 16,
          textTransform: "uppercase",
          "&:hover": {
            backgroundColor: colors.wine,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.wine,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: colors.partnersGray,
          fontSize: "20px",
          fontWeight: 400,
          backgroundColor: "#fff",
          borderRadius: 8,
          color: colors.lightGray,
          "& .Mui-focused": {
            color: `${colors.lightGray} !important`,
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "yellow",
          },
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: `${colors.lightGray} !important`,
            },
            "&.Mui-focused fieldset": {
              borderColor: `${colors.lightGray} !important`,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#5A67DF",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#8C8A97",
          fontWeight: 600,
          fontSize: "12px",
          fontFamily: "Montserrat !important",
          border: "1px solid #DDEAF3",
          cursor: "pointer",
          backgroundColor: "#FAF9F8",
          "& .MuiChip-deleteIcon": {
            width: "12px",
            height: "12px",
          },
          "& .MuiChip-avatar": {
            borderRadius: "100%",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#5F0329",
          "& .MuiSlider-root": {
            color: "#5F0329",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0px 0px 10px 10px",
          color: "#fff",
          fontFamily: "Montserrat !important",
          fontSize: "13px",
          "& .MuiButton-startIcon": {
            marginRight: "10px",
            marginLeft: "-2px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "9px",
          color: colors.rose,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
});
