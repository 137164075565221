import React from "react";
import { Box, Container } from "@mui/material";
import colors from "src/shared/theme/colors";
import { ReactComponent as MenuHamburguer } from "src/assets/svg/MenuHamburguer.svg";
import { ReactComponent as Logo } from "src/assets/svg/logo-type.svg";
// import { HeaderSearchBar } from "src/components/HeaderSearchBar";
import { Navigation } from "src/components/Navigation";
import { useAuth } from "src/shared/providers/auth";

import * as Styled from "./styles";

const Header: React.FC = () => {
  const { isLogged } = useAuth();
  const [openNav, setOpenNav] = React.useState<boolean>(false);

  return (
    <>
      {isLogged ? (
        <Box
          sx={{
            backgroundColor: colors.white,
            boxShadow: "0px 0px 30px rgba(51, 48, 62, 0.2)",
            marginBottom: "40px",
          }}
        >
          <Container>
            <Styled.Header>
              <Styled.Menu onClick={() => setOpenNav(true)}>
                <MenuHamburguer />
                <Styled.Logo>
                  <Logo />
                  <span>Best Hunter Wine</span>
                </Styled.Logo>
              </Styled.Menu>
              {/* <HeaderSearchBar /> */}
            </Styled.Header>
          </Container>
          <Navigation
            open={openNav}
            loggedIn={true}
            onClose={() => setOpenNav(false)}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export { Header };
