import React from "react";
import { Typography } from "@mui/material";
import { ReactComponent as ArrowGoBack } from "src/assets/svg/ArrowGoBack.svg";

// import { defineMessages, useIntl } from "react-intl";
import { DateTime } from "luxon";

import * as Styled from "./styles";
import { formatMoney } from "src/shared/providers/money";

interface IProps {
  id: number;
  price: number;
  date: string;
  trackingReference: string;
}

// const messages = defineMessages({
//   orderDelivered: { id: "order.header.delivered" },
//   trackingCode: { id: "order.header.tracking.code" },
//   purchase: { id: "order.header.purchase" },
// });

const OrderHeader: React.FC<IProps> = ({
  id,
  price,
  date,
  trackingReference,
}) => {
  // const { formatMessage } = useIntl();
  return (
    <Styled.Content>
      <Styled.Header>
        <Styled.GoBack href="/">
          <Styled.GoBackTitle>
            <ArrowGoBack />
            <Typography variant="h3">Pedido #{id}</Typography>
          </Styled.GoBackTitle>
          <Typography className="price">
            {price && formatMoney(price)}
          </Typography>
        </Styled.GoBack>
      </Styled.Header>
      <Styled.Date>
        <Typography>
          {date && DateTime.fromISO(date).toFormat("dd/MM/yyyy")}
        </Typography>
      </Styled.Date>
    </Styled.Content>
  );
};

export { OrderHeader };
