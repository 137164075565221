import styled from "styled-components";

export const Institutional = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: flex-start;
  p {
    a {
      color: inherit;
      text-decoration: none;
      font-size: 13px;
    }
  }
`;
