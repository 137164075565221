import React from "react";
import { Typography } from "@mui/material";

import {
  FaCcPaypal,
  FaCcVisa,
  FaCcMastercard,
  FaBarcode,
} from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import {ReactComponent as PaymentTypes} from "src/assets/svg/paymentsTypes.svg";

import * as Styled from "./styles";

const PaymentFooter: React.FC = () => {
  return (
    <Styled.Payment>
      <Typography style={{ fontWeight: "bold", marginBottom: "5px" }}>
      Formas de pagamento
      </Typography>
      <div>
        <PaymentTypes />
      </div>
    </Styled.Payment>
  );
};

export { PaymentFooter };
